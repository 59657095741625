export function convertDaysToTerms(days: number): string {
  if (days === 1) {
    return "N/A";
  } else if (days < 30) {
    return `${days} días`;
  } else {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    let result = `${months} ${months === 1 ? "mes" : "meses"}`;

    if (remainingDays > 0) {
      result += ` y ${remainingDays} ${remainingDays === 1 ? "día" : "días"}`;
    }

    if (result === "NaN meses") return "N/A";
    return result;
  }
}
