import useSupplierBillStore from "@/store/SupplierBills";
import { SupplierInvoice } from "@Adapters/getBillsBySupplier.adapter";
import EmptyState from "@Components/ui/EmptyState/EmptyState";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SupplierBillsItem from "@Pages/SupplierEdition/views/SupplierBills/components/SupplierBillsItem/SupplierBillsItem";
import { useParams } from "react-router-dom";
import styles from "../components/SupplierListTable/SupplierListTable.module.css";
import SupplierBillsPreview from "@Pages/SupplierEdition/views/SupplierBillsPreview/SupplierBillsPreview";

const BillsToPayView = () => {
  const [billsToPay, setBillsToPay] = useState<SupplierInvoice[] | null>(null);
  const bills = useSupplierBillStore();
  const supplierParams = useParams();
  const [showBillDetail, setShowBillDetail] = useState({
    show: false,
    id: null,
    detail: null,
  });
  const [firstRender, setFirstRender] = useState(true);
  const getNoPaidBills = async () => {
    const response = await bills.getNoPaidBills();
    setBillsToPay(response);
    setFirstRender(false);
  };

  useEffect(() => {
    getNoPaidBills();
  }, []);

  useEffect(() => {
    if (firstRender) return;

    setBillsToPay(bills.noPaidBillsCached);
  }, [bills.noPaidBillsCached]);

  if (billsToPay === null) return <SkeletonText />;
  return (
    <section>
      {billsToPay.length === 0 ? (
        <EmptyState
          title="No hay cuentas por pagar"
          message="Todas las facturas registradas se encuentran pagas o no hay facturas registradas"
          icon="BILL"
        />
      ) : (
        <section className={styles.supplierTable__main}>
          {billsToPay.map((b) => {
            return (
              <SupplierBillsItem
                key={uuidv4()}
                billInfo={b}
                supplierId={supplierParams.idSupplier}
                setShowBillDetail={setShowBillDetail}
              />
            );
          })}
          {showBillDetail.show && (
            <SupplierBillsPreview
              id={showBillDetail.id}
              detail={showBillDetail.detail}
              setShowVisible={setShowBillDetail}
            />
          )}
        </section>
      )}
    </section>
  );
};

export default BillsToPayView;
