import { Skeleton } from "@mui/material";
import { LoadingSpinner, ModalAcceptCancel } from "@viuti/recursos";

/**
 * Component that displays skeleton loaders for text elements.
 * This is used to show a placeholder while content is loading.
 * @returns {JSX.Element} - The SkeletonModal component displaying skeleton loaders.
 */
const SkeletonModal = () => {
  return (
    <section>
      {/* Skeleton loader for a text element with a height of 40 pixels and full width */}
      <Skeleton id="skeleton-text-1" variant="text" height={40} width={320} />

      {/* Skeleton loader for a text element with a height of 40 pixels and full width */}
      <Skeleton id="skeleton-text-2" variant="text" height={40} width={320} />

      {/* Skeleton loader for a text element with a height of 40 pixels and full width */}
      <Skeleton
        id="skeleton-text-3"
        variant="rectangular"
        height={150}
        width={320}
      />
    </section>
  );
};

export default SkeletonModal;
