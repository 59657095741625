import { create } from "zustand";
import { IModalStore } from "./types/modalType";
import { toggleCreationSupplierModalController } from "./controller/modal.controller";

// Create the modal store using Zustand
const useModalStore = create<IModalStore>((set) => ({
  creationSupplierModal: false, // Initial state: modal is hidden

  toggleCreationSupplierModal: () => toggleCreationSupplierModalController(),
}));

export default useModalStore;
