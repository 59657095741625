// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o9jicj6RwkwPF1lBfGVM {
  width: 760px;
  display: grid;
  gap: 8px;
}

.Zoi6ipLvaJqMNyyRJ3CD {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.PYi5U0EDX2QPqyVWZbIG {
  color: #d7696d;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/PaymentHistory/PaymentHistory.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".modal {\n  width: 760px;\n  display: grid;\n  gap: 8px;\n}\n\n.info {\n  display: grid;\n  grid-template-columns: 20px auto;\n  gap: 0.5rem;\n  margin: 0.5rem 0;\n}\n\n.negative {\n  color: #d7696d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `o9jicj6RwkwPF1lBfGVM`,
	"info": `Zoi6ipLvaJqMNyyRJ3CD`,
	"negative": `PYi5U0EDX2QPqyVWZbIG`
};
export default ___CSS_LOADER_EXPORT___;
