import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

// 🌟 Context initial interface and values
interface FilterContextType {
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
}

interface FilterState {
  providers: {
    bank: string | null;
    credit: string | null;
  };
  accountsToPay: {
    paymentStatus: string | null;
    dateRange: {
      from: Date | null;
      to: Date | null;
    };
  };
}

const FilterContextInitialValue: FilterContextType = {
  filters: {
    providers: {
      bank: null,
      credit: null,
    },
    accountsToPay: {
      paymentStatus: null,
      dateRange: {
        from: null,
        to: null,
      },
    },
  },

  setFilters: () => {},
};

// 🌟 Create the Filter Context
export const FilterContext = createContext<FilterContextType>(
  FilterContextInitialValue
);

// 🌟 Context provider to wrap around components needing the filter functionality
export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<FilterState>(
    FilterContextInitialValue.filters
  );

  return (
    // 🛠️ Provide the context to the children
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

// ⚠️ Hook to use the filter context with error handling
export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("FilterContext must be used within a FilterProvider");
  }
  return context;
};
