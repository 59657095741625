import { TabNavigation } from "@viuti/recursos";
import SupplierInformationView from "./views/SupplierInformation/SuppllierInformation.view";
import { SupplierInformationProvider } from "./context/useSupplierInformation.context";
import SupplierBills from "./views/SupplierBills/SupplierBills";
import useValidateSupplierForm from "@/hook/useValidateForm";

const navigationViews = (supplierForm) => [
  {
    name: "Información",
    component: <SupplierInformationView SupplierForm={supplierForm} />,
  },
  {
    name: "Facturas emitidas",
    component: <SupplierBills />,
  },
];

const SupplierEdition = () => {
  const { supplierForm } = useValidateSupplierForm();
  return (
    <SupplierInformationProvider>
      <br style={{ marginTop: "2rem" }} />
      <TabNavigation views={navigationViews(supplierForm)} initialView={0} />
    </SupplierInformationProvider>
  );
};

export default SupplierEdition;
