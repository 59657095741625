import React from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { TextInput, SelectInput } from "@viuti/recursos";
import { ITextInputProps } from "@Models/interfaces/Resources.interfaces";

interface FormInputProps {
  nameControl: string;
  label: string;
  type?: "text" | "select";
  options?: { value: any; name: string }[];
  defaultValue?: any;
  rules?: RegisterOptions;
  prefix?: string;
  onSelect?: (n: string) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  nameControl,
  label,
  prefix = "",
  type = "text",
  options,
  defaultValue,
  rules,
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={defaultValue || ""}
      rules={rules}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { isDirty, isTouched } = fieldState;

        const commonProps: ITextInputProps = {
          label,
          value,
          inputProps: {
            onChange: (e) => onChange(e.target.value),
          },
          prefix: prefix,
          disabled: !!rules?.disabled,
          touched: isTouched,
          required: !!rules.required,
          error: handleErrorMessage(value, rules),
        };

        return (
          <>
            {type === "text" && <TextInput {...commonProps} />}
            {type === "select" && options && (
              <SelectInput
                label={label}
                value={defaultValue}
                items={options}
                handleChange={(e) => {
                  setValue(nameControl, e.target.value);
                }}
                {...commonProps}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default FormInput;

const handleErrorMessage = (value: any, rules: RegisterOptions): string => {
  const REQUIRED_ERROR = "Este campo es obligatorio.";
  const PATTERN_ERROR = "El campo no es válido.";

  if (
    rules.required &&
    (value === "" || value === undefined || value === null)
  ) {
    return REQUIRED_ERROR;
  }

  // @ts-ignore
  if (rules.pattern && typeof rules.pattern?.value === "object") {
    //@ts-ignore
    const pattern = rules.pattern?.value;
    if (pattern instanceof RegExp && !pattern.test(value)) {
      //@ts-ignore
      return rules.pattern?.message || PATTERN_ERROR;
    }
  }

  return "";
};
