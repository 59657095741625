import {
  ISupplierListAdapterResponse,
  Supplier,
  SupplierBackend,
} from "@Models/interfaces/Adapters.interfaces";
import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";
import getSuppliersAPI from "@Services/get_services/getSuppliers.services";

/**
 * Maps raw supplier data to the expected Supplier format.
 * @param data - Array of raw supplier data from the API.
 * @returns Array of suppliers formatted as per the Supplier interface.
 */
export const mapSupplierData = (data: SupplierBackend[]): Supplier[] => {
  return data.map((supplier) => ({
    id: supplier.id.toString(),
    name: supplier.fullName || "Sin especificar",
    taxId: Number(supplier.taxType) || 0,
    taxNumber: supplier.taxNumber.toString() || "Sin especificar",
    bankName: supplier.bankName || "Sin especificar",
    bankAccount: supplier.accountNumber || "Sin especificar",
    interbankAccount: supplier.interbankingNumber || "Sin especificar",
    creditDays: supplier.creditLimit || "Sin especificar",
    address: supplier.address || "Sin especificar",
  }));
};

/**
 * Fetches and adapts a paginated list of suppliers.
 * @param page - The page number to fetch.
 * @param max - The maximum number of items per page.
 * @returns A promise that resolves to the adapted supplier list response.
 */
export const getSupplierListAdapter = async (
  page: number
): Promise<ISupplierListAdapterResponse> => {
  try {
    // Fetch raw supplier data from the API
    //@ts-ignore
    const response = await getSuppliersAPI(page);

    const { data } = response;

    // Map the raw data to the Supplier format
    //@ts-ignore
    const mappedData = mapSupplierData(data.suppliers);

    return {
      isSuccess: true,
      data: mappedData,
      maxPages: data.totalPage,
      userMessage: "",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      maxPages: 1,
      userMessage: GENERIC_ERROR_MSG,
    };
  }
};
