import SupplierListTable from "../components/SupplierListTable/SupplierListTable";
import { useSupplierListPagination } from "../components/SupplierListTable/hook/useSupplierListPagination";
import useDataTable from "../components/SupplierListTable/hook/useDataTable";
import useWindowWidth from "@/hook/useWidth";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";
import EmptyState from "@Components/ui/EmptyState/EmptyState";
import {
  PaginationWrapper,
  SearchWithColoredBorder,
  TableMobile,
} from "@viuti/recursos";
import styles from "../components/SupplierListTable/SupplierListTable.module.css";
import { useState } from "react";
import useDebouncedFetch from "./hook/useDebouncedFetch";
import { navigateToUrl } from "single-spa";

const SupplierListView = () => {
  // Extract supplier list from the custom hook
  const { supplierList, currentPage, changeCurrentPage, optionsArray } =
    useSupplierListPagination();
  const [searchInput, setSearchInput] = useState("");

  // Extract data table properties from the custom hook
  const { suppliersDataTableProps } = useDataTable(
    supplierList === "LOADING" ? [] : supplierList
  );

  // Get the current window width
  const width = useWindowWidth();

  const { result } = useDebouncedFetch(searchInput);

  // Render skeleton loaders while data is being fetched
  if (supplierList === "LOADING") return <SkeletonText />;

  // Render a message when there are no suppliers available
  if (supplierList.length === 0) {
    return (
      <EmptyState
        title="No hay proveedores"
        message="No hay proveedores registrados en este local"
      />
    );
  }

  // Render mobile table if the window width is less than or equal to 820 pixels
  if (width <= 820)
    return (
      <section className={styles.supplierTable__main}>
        <TableMobile dataTable={suppliersDataTableProps} isLoading={false} />
      </section>
    );
  return (
    <section className={styles.supplierTable__main}>
      <header>
        <SearchWithColoredBorder
          placeholder="Buscar proveedores por nombre"
          value={searchInput}
          setValue={setSearchInput}
          allData={[...result]}
          onClickResult={(e) => navigateToUrl("/proveedores/editar/" + e.id)}
          showResults={true}
          clearInput={false}
        />
      </header>
      <section className={styles.supplierTable__main__scroll}>
        {/* Render the supplier list table */}
        <SupplierListTable supplierList={supplierList} />
      </section>
      <PaginationWrapper
        currentPagination={currentPage}
        setCurrentPagination={changeCurrentPage}
        options={optionsArray}
        optionsPerPage={10}
        isFixedToBottom={true}
      />
    </section>
  );
};

export default SupplierListView;
