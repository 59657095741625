import { SlideNotification } from "@viuti/recursos";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

// 🌟 Context initial interface and values
interface ToasterContextType {
  setToasterProps: Dispatch<SetStateAction<ToasterProps>>;
}

interface ToasterProps {
  message: string;
  isError: boolean;
}

const ToasterContextInitialValue: ToasterContextType = {
  setToasterProps: () => {},
};

// 🌟 Create the Toaster Context
export const ToasterContext = createContext<ToasterContextType>(
  ToasterContextInitialValue
);

// 🌟 Context provider to wrap around components needing the toaster functionality
const CUSTOM_DURATION_TIME = 5000;
export const ToasterProvider = ({ children }: { children: ReactNode }) => {
  const [toasterProp, setToasterProp] = useState<ToasterProps | null>(null);

  return (
    // 🛠️ Provide the context to the children
    <ToasterContext.Provider value={{ setToasterProps: setToasterProp }}>
      {children}
      {toasterProp && (
        <SlideNotification
          state={{
            message: toasterProp.message,
            status: toasterProp.isError ? 400 : 200,
            toastTimeDuration: CUSTOM_DURATION_TIME,
          }}
          clearStatus={() => setToasterProp(null)}
        />
      )}
    </ToasterContext.Provider>
  );
};

// ⚠️ Hook to use the toaster context with error handling
export const useToasterContext = () => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error("ToasterContext must be used within a ToasterProvider");
  }
  return context;
};
