import { GET_INVOICE_DETAIL } from "@Models/api/api.routes";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { IBackendDetailResponse } from "@Models/interfaces/services.response";

/**
 * Fetches the detail of a supplier invoice from the API.
 * @param id - The ID of the invoice to fetch.
 * @returns {Promise<IApiResponse<IBackendDetailResponse[]>>} - A promise that resolves to the API response.
 */
const getSupplierDetailInvoiceAPI = async (
  id: number
): Promise<IApiResponse<IBackendDetailResponse[]>> => {
  try {
    const backResponse = await axios.get<{ data: IBackendDetailResponse[] }>(
      GET_INVOICE_DETAIL,
      {
        params: {
          BillId: id,
        },
      }
    );

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getSupplierDetailInvoiceAPI;
