import useBankStore from "@/store/bank.store";
import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import { useEffect, useState } from "react";

const useGetBankList = () => {
  const [bankOptions, setBankOptions] = useState<
    { value: number; name: string }[]
  >([]);

  const findOrLoadBank = useBankStore((state) => state.loadBanksIfEmpty);

  useEffect(() => {
    const loadBank = async () => {
      const foundBank = await findOrLoadBank();
      setBankOptions(foundBank);
    };

    loadBank();
  }, [findOrLoadBank]);

  return { bankOptions };
};

export default useGetBankList;
