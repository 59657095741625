import useSupplierStore from "@/store/Suppliers.store";
import { useHeaderNavigationContext } from "@Contexts/HeaderNavigation/HeaderNavigation.context";
import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useGetSupplierInformation = () => {
  const contextHeader = useHeaderNavigationContext();
  const [supplierValues, setSupplierValues] = useState<ISupplierForm | null>(
    null
  );
  const [hasErrorFetchingData, setHasErrorFetchingData] = useState(false);
  const supplierParams = useParams();
  const supplierListStore = useSupplierStore((state) => state.supplierList);

  useEffect(() => {
    const supplierFindStore = supplierListStore.filter(
      (el) => el.id === supplierParams.idSupplier
    );
    if (supplierFindStore.length !== 0)
      setSupplierValues({
        businessName: supplierFindStore[0].name,
        taxIdVat: supplierFindStore[0].taxNumber.toString(),
        address: supplierFindStore[0].address || "",
        country: supplierFindStore[0].country || "",
        bankName: supplierFindStore[0].bankName,
        accountNumber: supplierFindStore[0].bankAccount,
        interbankAccountNumber: supplierFindStore[0].interbankAccount,
        creditTerms: supplierFindStore[0].creditDays,
        typeIdVat: 1,
      });
  }, []);

  const activeTheEditMode = () => {
    const editProps = { ...contextHeader.headerProp, title: "Hola" };
  };

  useEffect(() => {
    setTimeout(() => {
      const valueRender = supplierListStore.filter(
        (el) => el.id === supplierParams.idSupplier
      );
      if (valueRender.length === 0) {
        setHasErrorFetchingData(true);
      }
    }, 4000);
  }, []);

  return { supplierValues, activeTheEditMode, hasErrorFetchingData };
};

export default useGetSupplierInformation;
