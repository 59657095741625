import {
  getSupplierInvoicesAdapter,
  SupplierInvoice,
} from "@Adapters/getBillsBySupplier.adapter";
import { getNoPaidInvoicesAdapter } from "@Adapters/getNoPaidBills.adapter";
import { togglePaymentStatusAdapter } from "@Adapters/togglePaymentStatus.adapter";
import createPartialPaymentAPI from "@Services/post_services/createPartialPayment.service";
import { create } from "zustand";

// Define the interface for the modal store state and actions
interface IModalStore {
  billsCached: {
    supplierId: number;
    bills: SupplierInvoice[];
  }[];

  noPaidBillsCached: SupplierInvoice[];

  getSupplierBillsById: (
    supplierId: number
  ) => Promise<SupplierInvoice[] | null>; // Cambiar a Promise para manejar la solicitud asíncrona

  toggleSupplierBillById: (
    supplierId: number,
    billId: number,
    isPaid: boolean,
    amount?: number
  ) => Promise<boolean>;

  removeSupplierBillById: (
    supplierId: number,
    billId: number
  ) => Promise<Boolean>;

  getNoPaidBills: () => Promise<SupplierInvoice[] | null>;
}

// Create the modal store using Zustand
const useSupplierBillStore = create<IModalStore>((set) => ({
  billsCached: [],

  noPaidBillsCached: [],

  getSupplierBillsById: async (supplierId: number) => {
    // Buscar en el caché
    const cachedEntry = useSupplierBillStore
      .getState()
      .billsCached.find((entry) => entry.supplierId === supplierId);

    if (cachedEntry) {
      // Si se encuentra en el caché, devolver las facturas
      return cachedEntry.bills;
    } else {
      // Si no se encuentra en el caché, buscar en el backend
      try {
        const backResponse = await getSupplierInvoicesAdapter(supplierId);
        if (backResponse.isSuccess) {
          // Actualizar el caché con las nuevas facturas
          set((state) => ({
            billsCached: [
              ...state.billsCached,
              { supplierId, bills: backResponse.data },
            ],
          }));
          return backResponse.data;
        } else {
          // Manejar el error si la respuesta del backend no es exitosa
          return null;
        }
      } catch (error) {
        // Manejar cualquier error en la solicitud al backend
        return null;
      }
    }
  },

  toggleSupplierBillById: async (
    supplierId: number,
    billId: number,
    isPaid: boolean,
    amount?: number
  ): Promise<boolean> => {
    try {
      const response = await togglePaymentStatusAdapter(billId, isPaid);

      if (!response.isSuccess) return false;
      if (!isPaid && amount !== undefined)
        await createPartialPaymentAPI({
          billId,
          amount: amount,
        });
      // Buscar en el caché
      const cachedEntry = useSupplierBillStore
        .getState()
        .billsCached.find((entry) => entry.supplierId === supplierId);
      console.log("cachedEntry", cachedEntry);

      const cachedNoPaidEntry = useSupplierBillStore
        .getState()
        .noPaidBillsCached.find((entry) => entry.billId === billId);
      console.log("cachedNoPaidEntry", cachedNoPaidEntry);

      if (cachedEntry) {
        // Actualizar el estado de pago de la factura en billsCached
        const updatedBills = cachedEntry.bills.map((bill) =>
          bill.billId === billId ? { ...bill, isPaid } : bill
        );

        // Actualizar el caché con la lista de facturas modificada
        set((state) => ({
          billsCached: state.billsCached.map((entry) =>
            entry.supplierId === supplierId
              ? { ...entry, bills: updatedBills }
              : entry
          ),
        }));

        return true;
      }

      if (cachedNoPaidEntry) {
        // Actualizar directamente el estado de pago de la factura en noPaidBillsCached
        set((state) => ({
          noPaidBillsCached: state.noPaidBillsCached
            .map((entry) =>
              entry.billId === billId ? { ...entry, isPaid } : entry
            )
            .filter((f) => f.isPaid !== true),
        }));

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  },

  removeSupplierBillById: async (
    supplierId: number,
    billId: number
  ): Promise<boolean> => {
    try {
      const cachedEntry = useSupplierBillStore
        .getState()
        .billsCached.find((entry) => entry.supplierId === supplierId);

      if (cachedEntry) {
        // Filtrar las facturas eliminando la factura con el billId proporcionado
        const updatedBills = cachedEntry.bills.filter(
          (bill) => bill.billId !== billId
        );

        // Actualizar el caché con la lista de facturas modificada
        set((state) => ({
          billsCached: state.billsCached.map((entry) =>
            entry.supplierId === supplierId
              ? { ...entry, bills: updatedBills }
              : entry
          ),
        }));

        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  },

  getNoPaidBills: async () => {
    // Buscar en el caché
    const cachedEntry = useSupplierBillStore.getState().noPaidBillsCached;

    if (cachedEntry.length !== 0) {
      // Si se encuentra en el caché, devolver las facturas
      return cachedEntry;
    } else {
      // Si no se encuentra en el caché, buscar en el backend
      try {
        const backResponse = await getNoPaidInvoicesAdapter();
        if (backResponse.isSuccess) {
          // Actualizar el caché con las nuevas facturas
          set((state) => ({
            noPaidBillsCached: backResponse.data,
          }));
          return backResponse.data;
        } else {
          // Manejar el error si la respuesta del backend no es exitosa
          return null;
        }
      } catch (error) {
        // Manejar cualquier error en la solicitud al backend
        return null;
      }
    }
  },
}));

export default useSupplierBillStore;
