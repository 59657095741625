//@ts-ignore
import { formatToSoles } from "@/utils/formatToSoles.util";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import {
  GENERIC_FAIL_TOASTER_MSG,
  GENERIC_SUCCESS_TOASTER_MSG,
} from "@Models/messages/generalMessages.messages";
import createPartialPaymentAPI from "@Services/post_services/createPartialPayment.service";
import { ModalAcceptCancel, TextInput } from "@viuti/recursos";
import { useContext, useState } from "react";

export const PartialPayment = ({
  visible,
  setVisible,
  setOtherVisible,
  id,
  supplierId,
  bill,
}) => {
  setOtherVisible(false);
  const toaster = useContext(ToasterContext);
  const [partialAmount, setPartialAmount] = useState("");

  // Maneja el cierre del modal de pago parcial
  const handleClose = () => {
    setVisible(false);
    setOtherVisible(true);
  };

  // Maneja la confirmación del pago parcial
  const handleConfirmPayment = async () => {
    // Aquí puedes añadir la lógica para confirmar el pago parcial
    console.log("Monto parcial:", partialAmount);
    const response = await createPartialPaymentAPI({
      billId: id,
      amount: parseFloat(partialAmount),
    });
    if (response.isSuccess) {
      toaster.setToasterProps(GENERIC_SUCCESS_TOASTER_MSG);
      handleClose();
    } else {
      toaster.setToasterProps(GENERIC_FAIL_TOASTER_MSG);
    }
  };

  return (
    <ModalAcceptCancel
      title="Pago parcial"
      visibility={{
        isVisible: visible,
        setIsVisible: setVisible,
      }}
      showButtons={{
        showButtonOne: true,
        showButtonTwo: true,
        showButtonClose: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: handleClose,
      }}
      buttonTwo={{
        textButtonTwo: "Confirmar pago",
        actionButtonTwo: handleConfirmPayment,
      }}
      actionButtonClose={handleClose}
    >
      <div style={{ width: "300px" }}>
        <TextInput
          label="Monto parcial"
          value={partialAmount}
          inputProps={{
            placeholder: "Ingresa el monto parcial",
            onChange: (e) => {
              const value = e.target.value;

              // Validar que solo contenga números
              if (/^\d*\.?\d*$/.test(value)) {
                setPartialAmount(value);
              }
            },
            // onBlur: (e) => {
            //   setPartialAmount(formatToSoles(e.target.value));
            // },
          }}
          required
          type="number"
        />
      </div>
    </ModalAcceptCancel>
  );
};
