import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import useSupplierStore from "../Suppliers.store";
import { getSupplierListAdapter } from "@Adapters/getSupplierList.adapter";
import { deleteSupplierListAdapter } from "@Adapters/deleteSupplierList.adapter";
import {
  DeleteContext,
  DeleteModalProvider,
  useDeleteModalContext,
} from "@Contexts/DeleteModal/DeleteModal.context";
import { useContext } from "react";

/**
 * Fetch a filtered list of suppliers based on pagination.
 * @param {number} page - The page number to fetch.
 * @param {number} max - The maximum number of items per page.
 * @returns {Promise<Supplier[]>} - A promise that resolves to the filtered list of suppliers.
 */
export const getSupplierListFilteredController = async ({
  page,
  max,
}: {
  page: number;
  max: number;
}): Promise<Supplier[]> => {
  const { supplierList } = useSupplierStore.getState();

  // Calculate the indices for pagination
  const startIndex = (page - 1) * max;
  const endIndex = startIndex + max;

  if (startIndex < supplierList.length) {
    return supplierList.slice(
      startIndex,
      Math.min(endIndex, supplierList.length)
    );
  } else {
    try {
      // Fetch the new page of suppliers
      const newPageRequested = await getSupplierListAdapter(page);
      const newSuppliers = newPageRequested.data;
      const maxPages = newPageRequested.maxPages;

      // Update the store with the new list of suppliers
      useSupplierStore.setState((state) => {
        // Create a map for existing suppliers by their IDs
        const existingSuppliersMap = new Map<string, Supplier>();
        state.supplierList.forEach((supplier) =>
          existingSuppliersMap.set(supplier.id, supplier)
        );

        // Add or update suppliers in the map
        newSuppliers.forEach((newSupplier) => {
          existingSuppliersMap.set(newSupplier.id, newSupplier);
        });

        // Convert the map back to an array
        const updatedSupplierList = Array.from(existingSuppliersMap.values());

        return { supplierList: updatedSupplierList, maxPages: maxPages };
      });

      return [...supplierList, ...newSuppliers];
    } catch (error) {
      // TODO: Notify user with an error message
      return [];
    }
  }
};

/**
 * Delete a supplier by ID.
 * @param {string} id - The ID of the supplier to delete.
 * @returns {Promise<Supplier[]>} - A promise that resolves to the updated list of suppliers without the deleted supplier.
 */
export const deleteSupplierController = async (
  id: string
): Promise<{
  newList: Supplier[];
  isSuccess: boolean;
}> => {
  const { supplierList } = useSupplierStore.getState();
  if (!id) {
    return {
      isSuccess: false,
      newList: supplierList,
    };
  }

  try {
    const { isSuccess } = await deleteSupplierListAdapter(parseInt(id, 10));

    if (isSuccess) {
      const updatedList = supplierList.filter((supplier) => supplier.id !== id);

      return {
        newList: updatedList,
        isSuccess: true,
      };
    } else {
    }
  } catch (error) {
    //TODO: ERROR
  }

  return {
    newList: supplierList,
    isSuccess: false,
  };
};

/**
 * Edit a supplier's information by ID.
 * @param {string} id - The ID of the supplier to edit.
 * @param {Supplier} newInformation - The new information to update the supplier with.
 * @param {Supplier[]} supplierList - The current list of suppliers.
 * @returns {Supplier[]} - The updated list of suppliers with the edited supplier.
 */
export const editSupplierController = (
  id: string,
  newInformation: Supplier,
  supplierList: Supplier[]
): Supplier[] => {
  if (!id || !newInformation) {
    return supplierList;
  }

  return supplierList.map((supplier) =>
    supplier.id === id ? { ...supplier, ...newInformation } : supplier
  );
};

/**
 * Add a new supplier to the list.
 * @param {Supplier} newSupplier - The new supplier to add.
 * @param {Supplier[]} supplierList - The current list of suppliers.
 * @returns {Supplier[]} - The updated list of suppliers with the new supplier added.
 */
export const addSupplierController = (
  newSupplier: Supplier,
  supplierList: Supplier[]
): Supplier[] => {
  if (!newSupplier || !newSupplier.id) {
    return supplierList;
  }
  const newListFilter = supplierList.filter((s) => s.id !== newSupplier.id);
  console.log(newListFilter);
  return [...newListFilter, newSupplier];
};
