import useSupplierStore from "@/store/Suppliers.store";
import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import { ISupplierCreationResponse } from "@Models/interfaces/services.response";
import {
  ISupplierForm,
  SupplierBackendRequest,
} from "@Models/interfaces/SupplierCreation.interface";
import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";
import createSupplierAPI from "@Services/post_services/createSupplier.services";

/**
 * Maps raw supplier data from backend response to the expected Supplier format.
 * @param response - Raw supplier data from the API response.
 * @returns Supplier formatted as per the Supplier interface.
 */
export const mapSupplierCreationData = (
  response: ISupplierCreationResponse
): Supplier => {
  const { supplierData } = response;

  return {
    id: supplierData.id.toString(),
    name: supplierData.fullName || "Sin especificar",
    taxId: Number(supplierData.taxType) || 0,
    taxNumber: supplierData.taxType.toString(),
    bankName: supplierData.bankName || "Sin especificar",
    bankAccount: supplierData.accountNumber || "Sin especificar",
    interbankAccount: supplierData.interbankingNumber || "Sin especificar",
    creditDays: supplierData.creditLimit
      ? supplierData.creditLimit.toString()
      : "Sin especificar",
    address: supplierData.address || "Sin especificar",
  };
};

export interface IAdapterCreateResponse {
  userMessage: string;
  isSuccess: boolean;
  data: ISupplierCreationResponse;
}
export const createSupplierAdapter = async (
  formData: ISupplierForm
): Promise<IAdapterCreateResponse> => {
  const supplierBackendRequest: SupplierBackendRequest = {
    fullName: formData.businessName,
    taxType: formData.typeIdVat, //[1] Ruc | [2] Rut | [3] Vat
    taxNumber: formData.taxIdVat,
    bankId: parseInt(formData.bankName, 10),
    accountNumber: formData.accountNumber,
    interbankingNumber: formData.interbankAccountNumber || "",
    creditLimit: formData.creditTerms || "",
    address: formData.address,
  };

  const response = await createSupplierAPI(supplierBackendRequest);

  if (response.isSuccess) {
    const adaptedResponse = mapSupplierCreationData(response.data);
    useSupplierStore.setState((state) => {
      const newState = state;
      newState.supplierList = [...newState.supplierList, adaptedResponse];
      return newState;
    });

    return {
      userMessage: GENERIC_SUCCESS_MSG,
      isSuccess: true,
      data: response.data,
    };
  }

  return {
    userMessage: response.userMessage || GENERIC_ERROR_MSG,
    isSuccess: false,
    data: null,
  };
};
