import {
  ISupplierBackendResponse,
  ISupplierResponse,
} from "@Models/interfaces/Adapters.interfaces";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { GET_PAGINATED_SUPPLIER } from "@Models/api/api.routes";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

/**
 * Fetches paginated suppliers from the API.
 * @param page - The page number for pagination.
 * @returns {Promise<IApiResponse<ISupplierBackendResponse>>} - A promise that resolves to the API response.
 */
const getSuppliersAPI = async (
  page: number
): Promise<IApiResponse<ISupplierBackendResponse>> => {
  const carga_proveedores = 75;
  trackUserAction(carga_proveedores);
  try {
    const backResponse = await axios.get<ISupplierResponse>(
      GET_PAGINATED_SUPPLIER,
      {
        params: {
          pageNumber: page,
          itemsPerPage: 10,
        },
      }
    );

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getSuppliersAPI;
