import {
  GET_BILLS_SUPPLIER_BY_ID,
  GET_NO_PAID_BILLS_SUPPLIER_BY_ID,
} from "@Models/api/api.routes";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { SupplierNoPaidInvoiceBackend } from "@Models/interfaces/services.response";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

/**
 * Fetches bills for a supplier by their ID.
 * @param id - The ID of the supplier.
 * @returns {Promise<IApiResponse<SupplierNoPaidInvoiceBackend>>} - A promise that resolves to the API response.
 */
const getNoPaidInvoicesAPI = async (): Promise<
  IApiResponse<SupplierNoPaidInvoiceBackend>
> => {
  const carga_cuentas_por_pagar = 76;
  trackUserAction(carga_cuentas_por_pagar);
  try {
    const backResponse = await axios.get<{
      data: SupplierNoPaidInvoiceBackend;
    }>(GET_NO_PAID_BILLS_SUPPLIER_BY_ID);

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getNoPaidInvoicesAPI;
