import LoadingIndicator from "@Components/ui/LoadingIndicator/LoadingIndicator";
import { HeaderNavigationContext } from "@Contexts/HeaderNavigation/HeaderNavigation.context";
import { HeaderNavigation } from "@viuti/recursos";
import React, { ReactNode, useContext } from "react";

/**
 * A component that provides the HeaderNavigation context and renders HeaderNavigation if `headerProp` is available.
 *
 * @param {ReactNode} children - The child components to render within the main content area.
 * @returns {JSX.Element} The component with HeaderNavigation and children.
 */
const HeaderNavigationTemplate: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { headerProp } = useContext(HeaderNavigationContext);

  if (!headerProp) return <LoadingIndicator />;

  return (
    <div id="viuti-front-mainContent">
      {/* Render HeaderNavigation if headerProp is provided */}
      {headerProp && <HeaderNavigation {...headerProp} />}
      {children}
    </div>
  );
};

export default HeaderNavigationTemplate;
