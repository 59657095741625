import {
  HiBanknotes,
  HiDocument,
  HiMiniArrowPath,
  HiTrash,
} from "react-icons/hi2";
import styles from "../../SupplierBills.module.css";
import { Fragment, useContext, useState } from "react";
import { SupplierInvoice } from "@Adapters/getBillsBySupplier.adapter";
import { convertIsoDateToFormatted } from "@/utils/dateFormat.util";
import { formatToSoles } from "@/utils/formatToSoles.util";
import useSupplierBillsAction from "./hooks/useSupplierBillActions";
import { DeleteContext } from "@Contexts/DeleteModal/DeleteModal.context";
import { navigateToUrl } from "single-spa";
import { BASE_ROUTE, SUPPLIER_ROUTES } from "@Models/supplier.routes";
import { PaymentHistory } from "@Components/ui/PaymentHistory/PaymentHistoy";
import { PartialPayment } from "@Components/ui/PaymentHistory/PartialPayment";

const SupplierBillsItem = ({
  setShowBillDetail,
  billInfo,
  supplierId,
}: {
  setShowBillDetail: any;
  billInfo: SupplierInvoice;
  supplierId: string;
}) => {
  const [paidState, setIsPaid] = useState(billInfo.isPaid);
  const deleteModal = useContext(DeleteContext);
  const { toggleSupplierBillPaidStatus, showBillDetail, deleteBill } =
    useSupplierBillsAction(supplierId, billInfo, setShowBillDetail);
  const [visible, setVisible] = useState(false);
  const [visiblePartial, setVisiblePartial] = useState(false);
  return (
    <Fragment>
      <article className={styles.supplierBills_item__main}>
        <div className={styles.supplierBills_items__grid}>
          <strong className={styles.__strong}>{billInfo.invoiceCode}</strong>
          <p className={styles.__paragraph}>Registrado por</p>
          <p className={styles.__paragraph}>Fecha de registro</p>
          <p className={styles.__rightText + " " + styles.__strong}>
            {formatToSoles(billInfo.totalAmount)}
          </p>
          <small className={styles.__small}>
            Vence: {convertIsoDateToFormatted(billInfo.dueDate).shortDate}
          </small>
          <small className={styles.__small}>{billInfo.registeredBy}</small>
          <small className={styles.__small}>
            {convertIsoDateToFormatted(billInfo.registrationDate).shortDate}
          </small>
          <small className={styles.__rightText + " " + styles.__small}>
            +{billInfo.productQuantity} Productos
          </small>
        </div>

        <div className={styles.supplierBills_item__actions}>
          {!paidState ? (
            <button
              className={styles.supplierBills_item__buttonAction__no_paid}
              onClick={() => setVisible(true)}
            >
              <HiBanknotes size={22} />
              Confirmar pagó
            </button>
          ) : (
            <button
              onClick={() => {
                deleteModal.onDelete(() => toggleSupplierBillPaidStatus());
                deleteModal.updateTexts(
                  "Anular pagó",
                  "Estas a punto de anular el pagó de una factura. ¿Desea continuar?"
                );
                deleteModal.showModal(true);
              }}
              className={styles.supplierBills_item__buttonAction__paid}
            >
              <HiMiniArrowPath size={22} />
              Anular pago
            </button>
          )}
          <button
            className={styles.supplierBills_item__buttonAction}
            onClick={() => showBillDetail()}
          >
            <HiDocument size={22} />
          </button>
          <button
            onClick={() => deleteBill()}
            className={styles.supplierBills_item__buttonAction__delete}
          >
            <HiTrash size={22} color="#d7696d" />
          </button>
        </div>
      </article>
      {visible && (
        <PaymentHistory
          visible={visible}
          setVisible={setVisible}
          setVisiblePartial={setVisiblePartial}
          toggleSupplierBillPaidStatus={toggleSupplierBillPaidStatus}
          totalFactura={billInfo.totalAmount}
          idFactura={billInfo.billId}
          visiblePartial={visiblePartial}
        />
      )}
      {visiblePartial && (
        <PartialPayment
          visible={visiblePartial}
          setVisible={setVisiblePartial}
          setOtherVisible={setVisible}
          id={billInfo.billId}
          supplierId={supplierId}
          bill={billInfo}
        />
      )}
    </Fragment>
  );
};

export default SupplierBillsItem;
