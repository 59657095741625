import { SupplierBackendRequest } from "@Models/interfaces/SupplierCreation.interface";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { CREATE_SUPPLIER } from "@Models/api/api.routes";
import { handleApiError, handleApiResponse } from "../api.services";
import { ISupplierCreationResponse } from "@Models/interfaces/services.response";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

/**
 * Simulates an API call to create a new supplier.
 * This function mimics an asynchronous API request and returns a simulated response.
 * @param supplierData - The supplier data to be sent to the backend.
 * @returns {Promise<IApiResponse<ISupplierCreationResponse>>} - A promise that resolves to the simulated API response.
 */
const createSupplierAPI = async (
  supplierData: SupplierBackendRequest
): Promise<IApiResponse<ISupplierCreationResponse>> => {
  const crea_nuevo_proveedor = 77;
  trackUserAction(crea_nuevo_proveedor);
  try {
    const response = await axios.post<ISupplierCreationResponse>(
      CREATE_SUPPLIER,
      supplierData
    );

    // Return a successful response
    return handleApiResponse(response, response.data);
  } catch (error) {
    // Return an error response with an appropriate message
    return handleApiError(error);
  }
};

export default createSupplierAPI;
