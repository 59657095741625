// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fv4ARb61EOhF9_ZZNytc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.2325rem;
  text-align: left;
  color: var(--supplier-primary-color);
  margin-bottom: 1rem;
}

.iyfADbw5octu69BmCLDV {
  width: 37.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SupplierCreation/SupplierCreationView.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".SupplierCreationView__subtitle {\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.25rem;\n  letter-spacing: 0.2325rem;\n  text-align: left;\n  color: var(--supplier-primary-color);\n  margin-bottom: 1rem;\n}\n\n.SupplierCreationView__container {\n  width: 37.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SupplierCreationView__subtitle": `fv4ARb61EOhF9_ZZNytc`,
	"SupplierCreationView__container": `iyfADbw5octu69BmCLDV`
};
export default ___CSS_LOADER_EXPORT___;
