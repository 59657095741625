import { LoadingSpinner } from "@viuti/recursos";
import React from "react";
import styles from "./LoadingIndicator.module.css";

/**
 * Displays a loading indicator.
 * Optimized with React.memo to avoid unnecessary re-renders.
 */
const LoadingIndicator: React.VFC = () => {
  return (
    <span className={styles.loading__view}>
      <LoadingSpinner />
    </span>
  );
};

export default React.memo(LoadingIndicator);
