import useBankStore from "@/store/bank.store";
import useSupplierStore from "@/store/Suppliers.store";
import { useToasterContext } from "@Contexts/Toaster/Toaster.context";
import {
  TYPE_CREDIT,
  TYPE_IDENTIFICATION,
} from "@Models/form_validators/CreationForm.validators";
import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";
import { useEffect, useState } from "react";
import { UseFormReturn, useFormState, useWatch } from "react-hook-form";

const useHeaderNavigationProps = (
  headerContext,
  newSupplierFormValues: UseFormReturn<ISupplierForm, any, undefined>,
  oldSupplierFormValues: ISupplierForm,
  id: string,
  watch: any
) => {
  const { headerProp, setHeaderProp } = headerContext;
  const [isEditingMode, setIsEditingMode] = useState(false);
  const supplierStore = useSupplierStore();
  const toaster = useToasterContext();
  const banks = useBankStore();

  useEffect(() => {
    if (
      headerProp.title === "Detalle del proveedor" &&
      headerProp.buttonProps.textBttn === "Editar"
    ) {
      setTimeout(() => {
        initHeaderProp(headerProp);
      }, 100);
    }
  }, [headerProp.title]);

  const initHeaderProp = (initProp) => {
    const addFunction = changeTheFunctionHeaderProp(
      initProp,
      activeTheEditMode
    );
    setHeaderProp(addFunction);
  };

  const activeTheEditMode = () => {
    const newBttnName = changeTheBttnNameHeaderProp(headerProp, "Guardar");
    const noAvailableBttn = changeTheBttnAvailableHeaderProp(newBttnName, true);
    setHeaderProp(
      changeTheFunctionHeaderProp(noAvailableBttn, saveTheNewSupplier)
    );
    setIsEditingMode(true);
    newSupplierFormValues.setValue(
      "creditTerms",
      oldSupplierFormValues.creditTerms
    );
  };

  const saveTheNewSupplier = async () => {
    if (oldSupplierFormValues === null) return;
    const currentValues = newSupplierFormValues.getValues();

    const isSuccess = await supplierStore.editSupplier(id.toString(), {
      id: id.toString(),
      name: currentValues.businessName,
      taxId: currentValues.typeIdVat,
      taxNumber: currentValues.taxIdVat,
      bankName: currentValues.bankName,
      bankAccount: currentValues.accountNumber,
      interbankAccount: currentValues.interbankAccountNumber,
      creditDays: currentValues.creditTerms,
      address: currentValues.address,
    });

    if (isSuccess)
      toaster.setToasterProps({
        message: GENERIC_SUCCESS_MSG,
        isError: false,
      });
    else {
      toaster.setToasterProps({
        message: GENERIC_ERROR_MSG,
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (!isEditingMode) return;
    if (newSupplierFormValues.formState.isValid) {
      console.log(oldSupplierFormValues, newSupplierFormValues.getValues());
      if (oldSupplierFormValues === null) return;
      const currentValues = newSupplierFormValues.getValues();
      const hasChanges =
        currentValues.businessName !== oldSupplierFormValues.businessName ||
        currentValues.address !== oldSupplierFormValues.address ||
        currentValues.accountNumber !== oldSupplierFormValues.accountNumber ||
        currentValues.interbankAccountNumber !==
          oldSupplierFormValues.interbankAccountNumber ||
        currentValues.creditTerms !== oldSupplierFormValues.creditTerms ||
        currentValues.taxIdVat !== oldSupplierFormValues.taxIdVat ||
        currentValues.typeIdVat !== oldSupplierFormValues.typeIdVat ||
        //@ts-ignore
        banks.banks.find((b) => b.value === currentValues.bankName)?.name !==
          oldSupplierFormValues.bankName;

      if (hasChanges) {
        setHeaderProp(
          changeTheBttnAvailableHeaderProp(
            headerProp,
            false //TODO: MAKE THE LOGIC TO "TRUE"
          )
        );
        return;
      }

      const noAvailableBttn = changeTheBttnAvailableHeaderProp(
        headerProp,
        true //TODO: MAKE THE LOGIC TO "TRUE"
      );
      setHeaderProp(
        changeTheFunctionHeaderProp(noAvailableBttn, saveTheNewSupplier)
      );
    } else {
      const noAvailableBttn = changeTheBttnAvailableHeaderProp(
        headerProp,
        true //TODO: MAKE THE LOGIC TO "TRUE"
      );
      setHeaderProp(
        changeTheFunctionHeaderProp(noAvailableBttn, saveTheNewSupplier)
      );
    }
  }, [watch]);

  return { isEditingMode };
};

const changeTheFunctionHeaderProp = (headerProp, callback) => {
  return {
    ...headerProp,
    buttonProps: {
      ...headerProp.buttonProps,
      handleClick: callback,
    },
  };
};

const changeTheBttnNameHeaderProp = (headerProp, name) => {
  return {
    ...headerProp,
    buttonProps: {
      ...headerProp.buttonProps,
      textBttn: name,
    },
  };
};

export const changeTheBttnAvailableHeaderProp = (headerProp, isDisabled) => {
  return {
    ...headerProp,
    buttonProps: {
      ...headerProp.buttonProps,
      isDisabled: isDisabled,
    },
  };
};

export default useHeaderNavigationProps;
