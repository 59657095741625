import {
  ISupplierBackendResponse,
  ISupplierResponse,
} from "@Models/interfaces/Adapters.interfaces";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { SEARCH_SUPPLIER_BY_NAME } from "@Models/api/api.routes";

/**
 * Fetches paginated suppliers from the API.
 * @param page - The page number for pagination.
 * @returns {Promise<IApiResponse<ISupplierBackendResponse>>} - A promise that resolves to the API response.
 */
const getSuppliersByNameAPI = async (
  value: string
): Promise<IApiResponse<ISupplierBackendResponse[]>> => {
  try {
    const backResponse = await axios.get<ISupplierResponse>(
      SEARCH_SUPPLIER_BY_NAME,
      {
        params: {
          search: value,
          maxResultsToShow: 5,
        },
      }
    );

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getSuppliersByNameAPI;
