import SupplierListItem from "../SupplierListItems/SupplierListItems";
import { Fragment } from "react/jsx-runtime";
import { Supplier } from "@Models/interfaces/Adapters.interfaces";

/**
 * Component to display a table of suppliers with pagination and loading states.
 * @returns {JSX.Element} - The SupplierListTable component displaying supplier information.
 */
const SupplierListTable = ({ supplierList }: { supplierList: Supplier[] }) => {
  // Render the list of suppliers with search functionality for larger screens
  return (
    <Fragment>
      {supplierList.map((supplier) => (
        <SupplierListItem
          key={supplier.id}
          name={supplier.name}
          taxId={supplier.taxId}
          taxNumber={supplier.taxNumber}
          bankName={supplier.bankName}
          bankAccount={supplier.bankAccount}
          interbankAccount={supplier.interbankAccount}
          creditDays={supplier.creditDays}
          id={supplier.id}
        />
      ))}
    </Fragment>
  );
};

export default SupplierListTable;
