// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fyInEbX936A3lCWOBBz7 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 37.5rem;
  max-width: 80rem;
  width: 100%;
  padding: 0.5rem;
}

._SUtLphxTPETzjCwqs0h {
  color: var(--supplier-primary-color);
  font-size: 1rem;
  font-weight: 700;
}

.KPUU4NF837tlUI3XHBEQ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.uxytQ78nCN42j1vnvlAj,
.olT9Lv9fioLmCEo1nSNF {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100% !important;
}

.KW_aKM2YFstet6HkmEra {
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SupplierCreation/components/SupplierForm/SupplierCreationForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".supplierCreationForm {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  min-width: 37.5rem;\n  max-width: 80rem;\n  width: 100%;\n  padding: 0.5rem;\n}\n\n.supplierCreationForm__titleSection {\n  color: var(--supplier-primary-color);\n  font-size: 1rem;\n  font-weight: 700;\n}\n\n.supplierCreationForm__grid__column2 {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1rem;\n  margin-bottom: 1rem;\n}\n\n.supplierCreationForm__fullWidth,\n.supplierCreationForm__fullWidth__invert {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1rem;\n  width: 100% !important;\n}\n\n.flex__gap {\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"supplierCreationForm": `fyInEbX936A3lCWOBBz7`,
	"supplierCreationForm__titleSection": `_SUtLphxTPETzjCwqs0h`,
	"supplierCreationForm__grid__column2": `KPUU4NF837tlUI3XHBEQ`,
	"supplierCreationForm__fullWidth": `uxytQ78nCN42j1vnvlAj`,
	"supplierCreationForm__fullWidth__invert": `olT9Lv9fioLmCEo1nSNF`,
	"flex__gap": `KW_aKM2YFstet6HkmEra`
};
export default ___CSS_LOADER_EXPORT___;
