import SupplierBillsItem from "./components/SupplierBillsItem/SupplierBillsItem";
import styles from "./SupplierBills.module.css";
import SupplierBillsPreview from "../SupplierBillsPreview/SupplierBillsPreview";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";
import EmptyState from "@Components/ui/EmptyState/EmptyState";
import useGetSupplierBills from "./hooks/useGetSupplierBills";
import { v4 as uuidv4 } from "uuid";

const SupplierBills = () => {
  const {
    responseError,
    bills,
    supplierParams,
    setShowBillDetail,
    showBillDetail,
  } = useGetSupplierBills();
  if (responseError)
    return (
      <EmptyState
        title="No se pudieron cargar las facturas"
        message="Las facturas no pudieron ser cargadas. Por favor intente de nuevo mas tarde"
        isError={true}
      />
    );

  if (bills === null) return <SkeletonText />;
  return (
    <section className={styles.supplierBills_list__main}>
      {!responseError && bills.length === 0 && (
        <EmptyState
          title="Sin facturas emitidas"
          message="El proveedor seleccionado no tiene facturas registradas."
          icon="BILL"
        />
      )}
      {!responseError &&
        bills.map((b) => {
          return (
            <SupplierBillsItem
              key={uuidv4()}
              billInfo={b}
              supplierId={supplierParams.idSupplier}
              setShowBillDetail={setShowBillDetail}
            />
          );
        })}
      {showBillDetail.show && (
        <SupplierBillsPreview
          id={showBillDetail.id}
          detail={showBillDetail.detail}
          setShowVisible={setShowBillDetail}
        />
      )}
    </section>
  );
};

export default SupplierBills;
