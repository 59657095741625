import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import { create } from "zustand";
import {
  getSupplierListFilteredController,
  deleteSupplierController,
  editSupplierController,
  addSupplierController,
} from "./controller/Supplier.controller";
import { devtools } from "zustand/middleware";
import { updateSupplierAdapter } from "@Adapters/updateSupplierAdapter";

// Define the interface for the supplier store state and actions
interface ISupplierStore {
  supplierList: Supplier[]; // Array to store the list of suppliers
  maxPages: number;
  getSupplierListFiltered: ({
    page,
    max,
  }: {
    page: number; // The current page number for pagination
    max: number; // The maximum number of suppliers to return per page
  }) => Promise<Supplier[]>; // Action to fetch a filtered list of suppliers
  deleteSupplier: (id: string) => Promise<{ isSuccess: boolean }>; // Action to delete a supplier by ID
  editSupplier: (id: string, newInformation: Supplier) => Promise<boolean>; // Action to update a supplier's details
  addASupplierIntoTheStore: (newSupplier: Supplier) => void; // Action to add a new supplier
}

export const sessionStorageKey = "supplierList";
export const expirationMinutes = 60; // 1 hour

// Create the supplier store using Zustand
const useSupplierStore = create<ISupplierStore>()(
  devtools((set) => ({
    maxPages: 1,
    supplierList: [],

    getSupplierListFiltered: async ({ page, max }) => {
      try {
        // Llamar al controlador para obtener la lista filtrada de proveedores
        const suppliers = await getSupplierListFilteredController({
          page,
          max,
        });

        return suppliers;
      } catch (error) {
        // Registrar el error y devolver una lista vacía en caso de fallo
        return [];
      }
    },

    deleteSupplier: async (id: string) => {
      try {
        // Call the controller function to delete the supplier
        const updatedSupplierList = await deleteSupplierController(id);
        // Update the store state with the new supplier list
        set(() => ({
          supplierList: updatedSupplierList.newList,
        }));
        // // Save the updated supplier list to session storage
        // sessionStorage.removeItem(sessionStorageKey);
        // sessionStorage.setItem(
        //   sessionStorageKey,
        //   JSON.stringify(updatedSupplierList)
        // );
        return { isSuccess: updatedSupplierList.isSuccess }; // Return success status
      } catch (error) {
        // Log the error and return failure status
        return { isSuccess: false };
      }
    },

    editSupplier: async (id: string, newInformation: Supplier) => {
      const response = await updateSupplierAdapter(Number(id), newInformation);
      if (!response.isSuccess) return false;
      set((state) => ({
        // Update the supplier list with the edited supplier details
        supplierList: editSupplierController(
          id,
          newInformation,
          state.supplierList
        ),
      }));
      return true;
    },

    addASupplierIntoTheStore: (newSupplier: Supplier) => {
      return set((state) => ({
        // Add the new supplier to the existing list
        supplierList: addSupplierController(newSupplier, state.supplierList),
      }));
    },
  }))
);

export default useSupplierStore;
