// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-size-base: 1rem;
}

.rzHz7OKoEm6P8xKLQBfs {
  width: 100%;
  max-width: 350px;
}

.CnZqvqEbcPfigA4MNNAd {
  color: #45348e;
  text-align: center;
  font-family: Mulish;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}

.TzbW7Ks8m1rhGpWYS7tp {
  color: #727070;
  text-align: center;
  font-family: Mulish;
  font-weight: 400;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/contexts/DeleteModal/DeleteModal.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[":root {\n  --font-size-base: 1rem;\n}\n\n.modal__container {\n  width: 100%;\n  max-width: 350px;\n}\n\n.modal__container__title {\n  color: #45348e;\n  text-align: center;\n  font-family: Mulish;\n  font-size: 20px;\n  font-weight: 800;\n  margin-bottom: 20px;\n}\n\n.modal__container__p {\n  color: #727070;\n  text-align: center;\n  font-family: Mulish;\n  font-weight: 400;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__container": `rzHz7OKoEm6P8xKLQBfs`,
	"modal__container__title": `CnZqvqEbcPfigA4MNNAd`,
	"modal__container__p": `TzbW7Ks8m1rhGpWYS7tp`
};
export default ___CSS_LOADER_EXPORT___;
