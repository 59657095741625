import {
  IUpdateSupplierRequest,
  ISupplierResponse,
  ISupplierBackendResponse,
} from "@Models/interfaces/Adapters.interfaces";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { UPDATE_PAYMENT_STATUS, UPDATE_SUPPLIER } from "@Models/api/api.routes";
import { handleApiError, handleApiResponse } from "@Services/api.services";

/**
 * Updates a supplier on the server.
 * @param {IUpdateSupplierRequest} supplierData - Data of the supplier to update.
 * @param {number} supplierId - ID of the supplier to update.
 * @returns {Promise<IApiResponse<ISupplierBackendResponse>>} - A promise that resolves with the server response.
 */
const toggleBillSupplierAPI = async ({
  billId,
  status,
}: {
  billId: number;
  status: number;
}): Promise<IApiResponse<ISupplierBackendResponse>> => {
  try {
    const backResponse = await axios.put<ISupplierResponse>(
      UPDATE_PAYMENT_STATUS,
      null,
      {
        params: {
          billId: billId,
          status: status,
        },
      }
    );

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default toggleBillSupplierAPI;
