import { format, parseISO, formatISO, isValid } from "date-fns";

export interface FormattedDate {
  shortDate: string;
  longDate: string;
  dateParts: {
    day: number;
    month: number;
    year: number;
  };
}

/**
 * Converts an ISO date string to an object with formatted date strings and date parts.
 * @param isoDate - The ISO date string to be converted.
 * @returns An object with formatted date strings and date parts.
 */
export function convertIsoDateToFormatted(
  isoDate: string
): FormattedDate | null {
  const date = parseISO(isoDate);

  if (!isValid(date)) {
    return {
      shortDate: "Sin especificar",
      longDate: "Sin especificar",
      dateParts: {
        day: 0,
        month: 0,
        year: 0,
      },
    };
  }

  const shortDate = format(date, "dd/MM/yyyy");
  const longDate = format(date, "dd/MM/yyyy HH:mm");

  const dateParts = {
    day: date.getDate(),
    month: date.getMonth() + 1, // Months are 0-based in JavaScript
    year: date.getFullYear(),
  };

  return {
    shortDate,
    longDate,
    dateParts,
  };
}

/**
 * Converts a formatted date object back to an ISO date string.
 * @param formattedDate - The formatted date object to be converted.
 * @returns An ISO date string representing the formatted date.
 */
export function convertFormattedToIsoDate(
  formattedDate: FormattedDate
): string {
  const {
    dateParts: { day, month, year },
  } = formattedDate;

  // Create a Date object from the given date components
  const date = new Date(year, month - 1, day); // Months are 0-based

  // Format the date to ISO string
  const isoDate = formatISO(date, { representation: "complete" });

  return isoDate;
}
