import { useDeleteModalContext } from "@Contexts/DeleteModal/DeleteModal.context";
import { AdapterResponse } from "@Models/interfaces/Adapters.interfaces";
import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";
import deleteSuppliersAPI from "@Services/delete_services/deleteSupplier.services";

/**
 * Fetches and adapts a paginated list of suppliers.
 * @param page - The page number to fetch.
 * @param max - The maximum number of items per page.
 * @returns A promise that resolves to the adapted supplier list response.
 */
export const deleteSupplierListAdapter = async (
  id: number
): Promise<AdapterResponse> => {
  try {
    // Fetch raw supplier data from the API
    const response = await deleteSuppliersAPI(id);

    return {
      isSuccess: response.isSuccess,
      userMessage: response.userMessage,
    };
  } catch (error) {
    return {
      isSuccess: false,
      userMessage: GENERIC_ERROR_MSG,
    };
  }
};
