import searchSupplierByNameAdapter from "@Adapters/searchSupplierByName.adapter";
import { useState, useEffect } from "react";

const useDebouncedFetch = (value: string, delay: number = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [result, setResult] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  useEffect(() => {
    (async () => {
      if (debouncedValue) {
        const response = await searchSupplierByNameAdapter(value);
        setResult(response.data);
      }
    })();
  }, [debouncedValue]);

  return {
    result: result.map((r) => ({ ...r, name: r.fullName })),
  };
};

export default useDebouncedFetch;
