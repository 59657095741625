import { GET_BILLS_SUPPLIER_BY_ID } from "@Models/api/api.routes";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { SupplierInvoiceBackend } from "@Models/interfaces/services.response";

/**
 * Fetches bills for a supplier by their ID.
 * @param id - The ID of the supplier.
 * @returns {Promise<IApiResponse<SupplierInvoiceBackend[]>>} - A promise that resolves to the API response.
 */
const getBillsByProviderById = async (
  id: number
): Promise<IApiResponse<SupplierInvoiceBackend[]>> => {
  try {
    const backResponse = await axios.get<{ data: SupplierInvoiceBackend[] }>(
      GET_BILLS_SUPPLIER_BY_ID,
      {
        params: {
          id: id,
        },
      }
    );

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getBillsByProviderById;
