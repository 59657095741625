import LoadingIndicator from "@Components/ui/LoadingIndicator/LoadingIndicator";
import { PaymentHistory } from "@Components/ui/PaymentHistory/PaymentHistoy";
import { MODIFY_SUPPLIER_UUID } from "@Models/contracts/securityAccess.contracts";
import { SUPPLIER_ROUTES } from "@Models/supplier.routes";
import SupplierEdition from "@Pages/SupplierEdition/SupplierEdition.page";
import SupplierMainView from "@Pages/SupplierList/SupplierMainView";
import { ProtectedRoute } from "@viuti/recursos";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

export default function SupplierRouter() {
  return (
    // 🔄 Suspense component to handle loading state while components load asynchronously
    <Suspense fallback={<LoadingIndicator />}>
      {/* 🗺️ Define nested routes for supplier-related pages */}
      <Routes>
        {/* 📋 Route to display list of suppliers */}
        <Route
          path={SUPPLIER_ROUTES.SHOW_LIST}
          element={<SupplierMainView />}
        />

        {/* 📋 Route to edit a supplier */}
        <Route
          path={SUPPLIER_ROUTES.EDIT_SUPPLIER + "/:idSupplier"}
          element={
            <ProtectedRoute
              accessKeyModule={MODIFY_SUPPLIER_UUID}
              element={<SupplierEdition />}
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
