import {
  ISupplierBillDetailById,
  ISupplierBillsById,
} from "@Models/interfaces/Adapters.interfaces";
import { IBackendDetailResponse } from "@Models/interfaces/services.response";
import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";
import getSupplierDetailInvoiceAPI from "@Services/get_services/getBillsDetailById.services";
import getBillsByProviderById from "@Services/get_services/getSupplierById.services";

export interface SupplierInvoiceDetail {
  name: string; // Código de la factura
  quantity: number; // Usuario que registró la factura, puede ser null
  price: number; // Fecha de registro de la factura
}

export const mapInvoiceData = (
  data: IBackendDetailResponse[]
): SupplierInvoiceDetail[] => {
  return data.map((bill) => ({
    name: bill.productName || "Sin especificar",
    quantity: bill.quantity || 0,
    price: bill.unitPrice || 0,
  }));
};

/**
 * Fetches and adapts the invoices for a given supplier by ID.
 * @param supplierId - The ID of the supplier to fetch invoices for.
 * @returns A promise that resolves to the adapted supplier invoice response.
 */
export const getSupplierDetailInvoiceAdapter = async (
  billId: number
): Promise<ISupplierBillDetailById> => {
  try {
    // Fetch raw invoice data from the API
    const response = await getSupplierDetailInvoiceAPI(billId);

    const { data } = response;

    // Map the raw data to the SupplierInvoice format
    const mappedData = mapInvoiceData(data);

    return {
      isSuccess: response.isSuccess,
      data: mappedData,
      userMessage: response.isSuccess ? "" : GENERIC_ERROR_MSG,
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      userMessage: GENERIC_ERROR_MSG,
    };
  }
};
