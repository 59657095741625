const DOMAIN = "https://gw-qa.zymple.io/almacen/v1";

export const GET_PAGINATED_SUPPLIER =
  DOMAIN + "/Supplier/GetAllPaginatedSuppliers";

export const SEARCH_SUPPLIER_BY_NAME =
  DOMAIN + "/Supplier/SearchSupplierByName";

export const CREATE_SUPPLIER = DOMAIN + "/Supplier/CreateNewSupplier";

export const PARTIAL_URL = DOMAIN + "/Supplier/CreateNewPayment";

export const DELETE_SUPPLIER = DOMAIN + "/Supplier/DeleteSupplierById";

export const GET_BANKS = DOMAIN + "/Supplier/GetBankList";

export const GET_INVOICE_DETAIL = DOMAIN + "/Supplier/SearchBillDetailById";

export const GET_BILLS_SUPPLIER_BY_ID =
  DOMAIN + "/Supplier/GetSupplierBillWithId";

export const GET_NO_PAID_BILLS_SUPPLIER_BY_ID =
  DOMAIN + "/Supplier/GetNoPaidPaginatedSupplierBills";

export const UPDATE_SUPPLIER = DOMAIN + "/Supplier/UpdateSupplierById";

export const UPDATE_PAYMENT_STATUS =
  DOMAIN + "/Supplier/UpdateBillPaymentStatus";

export const PAYMENT_HISTORY = DOMAIN + "/Supplier/GetPaymentHistory";

export const GET_PAYMENT_HISTOY = DOMAIN + "/Supplier/GetPaymentHistory";
