import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import { useContext } from "react";

export function useCopy() {
  const toaster = useContext(ToasterContext);

  const copyToClipboard = (text) => {
    if (!text) return;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toaster.setToasterProps({
          isError: false,
          message: "Copiado al portapapeles",
        });
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles:", err);
        toaster.setToasterProps({
          isError: true,
          message: "No se pudo copiar al portapapeles",
        });
      });
  };

  return copyToClipboard;
}
