import { Skeleton } from "@mui/material";

/**
 * Component that displays skeleton loaders for text elements.
 * This is used to show a placeholder while content is loading.
 * @returns {JSX.Element} - The SkeletonText component displaying skeleton loaders.
 */
const SkeletonText = () => {
  return (
    <section>
      {/* Skeleton loader for a text element with a height of 40 pixels and full width */}
      <Skeleton variant="text" height={40} width="100%" />

      {/* Another skeleton loader for a text element with the same dimensions */}
      <Skeleton variant="text" height={40} width="100%" />

      {/* Another skeleton loader for a text element with the same dimensions */}
      <Skeleton variant="text" height={40} width="100%" />
    </section>
  );
};

export default SkeletonText;
