import { BASE_ROUTE } from "@Models/supplier.routes";
import SupplierRouter from "@Routes/supplier.routes";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderNavigationTemplate from "@/templates/HeaderNavigation.template";
import RenderProviderTemplate from "@/templates/RenderProviders.template";

// 🏠 Main route page of the application
export default function App() {
  return (
    <Router basename={BASE_ROUTE}>
      {/* Render all the providers needed */}
      <RenderProviderTemplate>
        {/* Use a template for consistent header navigation layout */}
        <HeaderNavigationTemplate>
          {/* 🗺️ Define and render the supplier routes */}
          <SupplierRouter />
        </HeaderNavigationTemplate>
      </RenderProviderTemplate>
    </Router>
  );
}
