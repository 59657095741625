import useSupplierStore from "@/store/Suppliers.store";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import {
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
  Supplier,
} from "@Models/interfaces/Adapters.interfaces";
import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";

import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";
import updateSupplierAPI from "@Services/put_services/updateSupplier.services";

interface IAdapterResponse {
  userMessage: string;
  isSuccess: boolean;
  data: IUpdateSupplierResponse;
}

export const updateSupplierAdapter = async (
  supplierId: number,
  formData: Supplier
): Promise<IAdapterResponse> => {
  const supplierBackendRequest: IUpdateSupplierRequest = {
    fullName: formData.name,
    taxType: formData.taxId, //[1] Ruc | [2] Rut | [3] Vat
    taxNumber: formData.taxNumber,
    bankId: 1,
    accountNumber: formData.bankAccount,
    interbankingNumber: formData.interbankAccount || "",
    creditLimit: formData.creditDays || "",
    address: formData.address,
  };

  const response = await updateSupplierAPI(supplierBackendRequest, supplierId);

  if (response.isSuccess) {
    return {
      userMessage: GENERIC_SUCCESS_MSG,
      isSuccess: true,
      //@ts-ignore
      data: null,
    };
  }

  return {
    userMessage: response.userMessage || GENERIC_ERROR_MSG,
    isSuccess: false,
    data: null,
  };
};
