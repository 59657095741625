import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

// 🌟 Context initial interface and values
interface SupplierInformationContextType {
  supplierInformation: ISupplierForm;
  setSupplierInformation: Dispatch<SetStateAction<ISupplierForm>>;
}

const SupplierInformationContextInitialValue: SupplierInformationContextType = {
  supplierInformation: {
    businessName: "",
    taxIdVat: "",
    address: "",
    country: "",
    bankName: "",
    typeIdVat: 0,
    accountNumber: "",
    interbankAccountNumber: "",
    creditTerms: "",
  },
  setSupplierInformation: () => {},
};

// 🌟 Create the Supplier Context
export const SupplierInformationContext =
  createContext<SupplierInformationContextType>(
    SupplierInformationContextInitialValue
  );

// 🌟 Context provider to wrap around components needing the toaster functionality
export const SupplierInformationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [supplierInformation, setSupplierInformation] =
    useState<ISupplierForm | null>(null);

  return (
    // 🛠️ Provide the context to the children
    <SupplierInformationContext.Provider
      value={{ setSupplierInformation, supplierInformation }}
    >
      {children}
    </SupplierInformationContext.Provider>
  );
};

// ⚠️ Hook to use the toaster context with error handling
export const useSupplierInformationContext = () => {
  const context = useContext(SupplierInformationContext);
  if (!context) {
    throw new Error(
      "SupplierInformationContext must be used within a SupplierInformationProvider"
    );
  }
  return context;
};
