import useSupplierBillStore from "@/store/SupplierBills";
import { SupplierInvoice } from "@Adapters/getBillsBySupplier.adapter";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useGetSupplierBills = () => {
  const [bills, setBills] = useState<SupplierInvoice[] | null>(null);
  const [responseError, setResponseError] = useState(false);
  const { getSupplierBillsById, billsCached } = useSupplierBillStore(
    (state) => state
  );
  const supplierParams = useParams();
  const [firstRender, setFirstRender] = useState(true);

  const [showBillDetail, setShowBillDetail] = useState({
    show: false,
    id: null,
    detail: null,
  });

  const getAllBills = async () => {
    const getBillsCached = await getSupplierBillsById(
      Number(supplierParams.idSupplier)
    );
    if (getBillsCached === null) setResponseError(true);
    setBills(getBillsCached);
    setFirstRender(false);
  };

  useEffect(() => {
    getAllBills();
  }, []);

  useEffect(() => {
    if (firstRender) return;
    const newBills = billsCached.filter(
      (b) => b.supplierId === Number(supplierParams.idSupplier)
    );
    setBills(newBills[0].bills);
  }, [billsCached]);

  return {
    responseError,
    bills,
    supplierParams,
    setShowBillDetail,
    showBillDetail,
  };
};

export default useGetSupplierBills;
