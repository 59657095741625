import { ISupplierBillsById } from "@Models/interfaces/Adapters.interfaces";
import {
  INoPaidBill,
  SupplierInvoiceBackend,
} from "@Models/interfaces/services.response";
import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";
import getNoPaidInvoicesAPI from "@Services/get_services/getNoPaidBills.services";
import getBillsByProviderById from "@Services/get_services/getSupplierById.services";

export interface SupplierInvoice {
  invoiceCode: string; // Código de la factura
  registeredBy: string | null; // Usuario que registró la factura, puede ser null
  registrationDate: string; // Fecha de registro de la factura
  totalAmount: number; // Monto total de la factura
  productQuantity: number; // Cantidad de productos en la factura
  paymentStatus: string; // Estado del pago de la factura
  dueDate: string; // Fecha de vencimiento de la factura
  issueDate: string; // Fecha de emisión de la factura
  isPaid: boolean;
  billId: number;
}

export const mapInvoiceData = (data: INoPaidBill[]): SupplierInvoice[] => {
  return data.map((invoice) => ({
    invoiceCode: invoice.codigoFactura || "Sin especificar",
    registeredBy: invoice.registradoPor || "Sin especificar",
    registrationDate: invoice.fechaRegistro || "Sin especificar",
    totalAmount: invoice.total || 0,
    productQuantity: invoice.cantidadProductos,
    paymentStatus: "1",
    dueDate: invoice.fechaVencimiento || "Sin especificar",
    issueDate: invoice.fechaEmision || "Sin especificar",
    isPaid: false,
    billId: invoice.facturaId,
  }));
};

/**
 * Fetches and adapts the invoices for a given supplier by ID.
 * @param supplierId - The ID of the supplier to fetch invoices for.
 * @returns A promise that resolves to the adapted supplier invoice response.
 */
export const getNoPaidInvoicesAdapter =
  async (): Promise<ISupplierBillsById> => {
    try {
      // Fetch raw invoice data from the API
      const response = await getNoPaidInvoicesAPI();

      const { data } = response;

      // Map the raw data to the SupplierInvoice format
      const mappedData = mapInvoiceData(data.bills);

      return {
        isSuccess: response.isSuccess,
        data: mappedData.reverse(),
        userMessage: response.isSuccess ? "" : GENERIC_ERROR_MSG,
      };
    } catch (error) {
      return {
        isSuccess: false,
        data: [],
        userMessage: GENERIC_ERROR_MSG,
      };
    }
  };
