// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zz7AG7IJe8j5XT1OJvic {
  display: grid;
  gap: 1rem;
  height: 20.625rem;
}

@media screen and (width <= 47.5rem) {
  .zz7AG7IJe8j5XT1OJvic {
    grid-template-columns: auto;
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/SupplierEdition/views/SupplierInformation/SupplierInformation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE;IACE,2BAA2B;IAC3B,YAAY;EACd;AACF","sourcesContent":[".SupplierInformation__section {\n  display: grid;\n  gap: 1rem;\n  height: 20.625rem;\n}\n\n@media screen and (width <= 47.5rem) {\n  .SupplierInformation__section {\n    grid-template-columns: auto;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SupplierInformation__section": `zz7AG7IJe8j5XT1OJvic`
};
export default ___CSS_LOADER_EXPORT___;
