import { IApiResponse } from "@Models/api/apiResponses";
import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";

export const getAuthHeaders = () => {
  const token = localStorage.getItem("access_token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const handleApiResponse = <T>(
  response: any = null,
  responseData: any = null,
  successMessage: string = GENERIC_SUCCESS_MSG,
  errorMessage: string = GENERIC_ERROR_MSG
): IApiResponse<T> => {
  try {
    return {
      isSuccess: response.status === 200 || response.status === 201,
      userMessage:
        response.status === 200 || response.status === 201
          ? successMessage
          : errorMessage,
      data: responseData || response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      userMessage: errorMessage,
      data: null,
    };
  }
};

export const handleApiError = <T>(
  error: any,
  errorMessage: string = GENERIC_ERROR_MSG
): IApiResponse<T> => {
  return {
    isSuccess: false,
    userMessage: errorMessage,
    data: null,
  };
};
