import useSupplierStore from "@/store/Suppliers.store";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import { GENERIC_FAIL_TOASTER_MSG } from "@Models/messages/generalMessages.messages";
import { useEffect, useState, useCallback, useContext } from "react";

const MAX_ITEMS_PER_PAGE = 10;

/**
 * Custom hook for managing pagination of the supplier list.
 * This hook provides the current supplier list, loading state, and error handling,
 * along with functions to manage page changes.
 *
 * @returns {Object} - An object containing:
 *  - supplierList: The current list of suppliers or "LOADING" state
 *  - currentPage: The current page number
 *  - changeCurrentPage: Function to update the current page number
 */
export const useSupplierListPagination = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toaster = useContext(ToasterContext);

  // State to manage the current page number
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Retrieve the Zustand store's state and actions
  const state = useSupplierStore((state) => state);

  useEffect(() => {
    (async () => {
      try {
        await state.getSupplierListFiltered({
          page: currentPage,
          max: MAX_ITEMS_PER_PAGE,
        });

        setIsLoading(false);
      } catch (error) {
        toaster.setToasterProps(GENERIC_FAIL_TOASTER_MSG);
      }
    })();
  }, []);

  // Return current state and actions for use in components
  const supplierList: "LOADING" | Supplier[] = isLoading
    ? "LOADING"
    : state.supplierList;
  return {
    supplierList: supplierList,
    currentPage,
    changeCurrentPage: setCurrentPage,
    optionsArray: Array(state.maxPages * 10).fill(null),
  };
};
