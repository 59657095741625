import { ModalAcceptCancel } from "@viuti/recursos";
import SupplierCreationForm from "./components/SupplierForm/SupplierCreationForm";
import useModalStore from "@/store/Modal.store";
import { createSupplierAdapter } from "@Adapters/createSupplier.adapter";
import { useContext, useState } from "react";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import useSupplierStore from "@/store/Suppliers.store";
import useValidateSupplierForm from "../../hook/useValidateForm";
import adaptTheSupplierStoreResponse from "./utils/adaptTheSupplierStore";
import styles from "./SupplierCreationView.module.css";

const SupplierCreationPage = () => {
  // Get the store needed elements (toggle Modal Function and add Supplier function)
  const { toggleCreationSupplierModal } = useModalStore();
  const { addASupplierIntoTheStore } = useSupplierStore();

  // Modals states
  const [isVisible, setIsVisible] = useState(true);
  const { setToasterProps } = useContext(ToasterContext);

  const { canCreateEditSupplier, supplierForm } = useValidateSupplierForm();

  return (
    <ModalAcceptCancel
      visibility={{
        isVisible: isVisible,
        setIsVisible: () => {},
      }}
      showButtons={{ showButtonTwo: true, showButtonClose: true }}
      title="Crear un nuevo proveedor"
      actionButtonClose={() => toggleCreationSupplierModal()}
      buttonTwo={{
        textButtonTwo: "Crear proveedor",
        isDisabled: !canCreateEditSupplier,
        actionButtonTwo: async () => {
          const response = await createSupplierAdapter(
            supplierForm.getValues()
          );

          if (response.isSuccess) {
            const values = supplierForm.getValues();
            setIsVisible(false);
            addASupplierIntoTheStore(
              adaptTheSupplierStoreResponse(response, values)
            );
          }

          return setToasterProps({
            message: response.userMessage,
            isError: !response.isSuccess,
          });
        },
      }}
    >
      <div className={styles.SupplierCreationView__container}>
        <SupplierCreationForm SupplierForm={supplierForm} />
      </div>
    </ModalAcceptCancel>
  );
};

export default SupplierCreationPage;
