import {
  GET_BILLS_SUPPLIER_BY_ID,
  GET_NO_PAID_BILLS_SUPPLIER_BY_ID,
  GET_PAYMENT_HISTOY,
} from "@Models/api/api.routes";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";
import { SupplierNoPaidInvoiceBackend } from "@Models/interfaces/services.response";

/**
 * Fetches bills for a supplier by their ID.
 * @param id - The ID of the supplier.
 * @returns {Promise<IApiResponse<SupplierNoPaidInvoiceBackend>>} - A promise that resolves to the API response.
 */
const getPaymentHistoryAPI = async (id: number): Promise<IApiResponse<any>> => {
  try {
    const backResponse = await axios.get(GET_PAYMENT_HISTOY, {
      params: {
        billId: id,
      },
    });

    // Use handleApiResponse to process the response
    return handleApiResponse(backResponse);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default getPaymentHistoryAPI;
