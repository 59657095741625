import { BankAdapterDataList } from "@Models/interfaces/Adapters.interfaces";
import useBankStore from "../bank.store";
import { getBanksListAdapter } from "@Adapters/getBanks.adapter";

export const getBanksListController = async (): Promise<
  BankAdapterDataList[]
> => {
  try {
    const banks = await getBanksListAdapter();

    useBankStore.setState({
      banks: banks.data,
    });

    return banks.data;
  } catch (error) {
    return [];
  }
};

export const loadBanksIfEmptyController = async (): Promise<
  BankAdapterDataList[]
> => {
  const { banks, getBanksListsStore } = useBankStore.getState();

  if (banks.length === 0) {
    return getBanksListsStore();
  }

  return banks;
};
