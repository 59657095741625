import { getAuthHeaders } from "@Services/api.services";
import axios from "axios";

// Define URLs for redirection on specific error statuses
const Error401URL = window.location.origin + "/";
const ServerErrorURL = window.location.origin + "/server-error";
const TIMEOUT = 15000;

// Configure Axios interceptors
axios.interceptors.request.use(
  (config) => {
    // Set timeout for all requests
    config.timeout = TIMEOUT;

    // Add Authorization header if available
    const authHeaders = getAuthHeaders();
    config.headers.Authorization = authHeaders.headers.Authorization;

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      localStorage.clear();
      // Redirect to login or error page for unauthorized access
      window.location.href = Error401URL;
    }

    return Promise.reject(error);
  }
);

export default axios;
