import useSupplierBillStore from "@/store/SupplierBills";
import { DeleteContext } from "@Contexts/DeleteModal/DeleteModal.context";
import { ToasterContext } from "@Contexts/Toaster/Toaster.context";
import {
  GENERIC_ERROR_MSG,
  GENERIC_FAIL_TOASTER_MSG,
  GENERIC_SUCCESS_MSG,
  GENERIC_SUCCESS_TOASTER_MSG,
} from "@Models/messages/generalMessages.messages";
import { useContext, useState } from "react";

const useSupplierBillsAction = (supplierId, billInfo, setShowBillDetail) => {
  const [paidState, setPaidState] = useState(billInfo.isPaid);
  const billStore = useSupplierBillStore();
  const deleteModal = useContext(DeleteContext);
  const toaster = useContext(ToasterContext);

  const toggleSupplierBillPaidStatus = async () => {
    console.log(billInfo.totalAmount);
    const response = await billStore.toggleSupplierBillById(
      Number(supplierId),
      billInfo.billId,
      !paidState,
      billInfo.totalAmount * -1
    );
    if (response) toaster.setToasterProps(GENERIC_SUCCESS_TOASTER_MSG);
    else toaster.setToasterProps(GENERIC_FAIL_TOASTER_MSG);
    setPaidState(!paidState);
  };

  const showBillDetail = () => {
    setShowBillDetail({
      show: true,
      id: billInfo.billId,
      detail: billInfo,
    });
  };

  const deleteBill = () => {
    deleteModal.updateTexts(
      "Eliminar factura",
      "Estas a punto de eliminar una factura, esta acción no se puedo deshacer y eliminará todos los productos ingresados por la misma. ¿Desea continuar?"
    );
    deleteModal.onDelete(() =>
      billStore.removeSupplierBillById(Number(supplierId), billInfo.billId)
    );
    deleteModal.showModal(true);
  };

  return {
    toggleSupplierBillPaidStatus,
    showBillDetail,
    deleteBill,
  };
};

export default useSupplierBillsAction;
