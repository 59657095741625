import { Supplier } from "@Models/interfaces/Adapters.interfaces";
import { useMemo } from "react";
//@ts-ignore
import styles from "../SupplierListTable.module.css";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";

/**
 * Custom hook to format supplier data for use in a data table.
 * Converts supplier objects into a structure suitable for rendering in a table.
 *
 * @param {Supplier[]} suppliers - Array of suppliers to be formatted
 * @returns {Object} - An object containing the formatted supplier data for the table
 */
const useDataTable = (suppliers: Supplier[]) => {
  // Memoize the formatted supplier data to avoid unnecessary recalculations
  const suppliersDataTableProps = useMemo(() => {
    // Map suppliers to the desired table format
    return suppliers.map((supplier) => ({
      id: supplier.id,
      header: [
        <p
          key={`header-${supplier.id}`}
          className={styles.supplierTable__row__title}
        >
          {supplier.name}
        </p>,
      ],
      body: [
        {
          item1: (
            <p
              key={`doc-${supplier.id}`}
              className={
                styles.supplierTable__text__opacity__underline__leftSide
              }
            >
              Nro documento (RUC)
            </p>
          ),
          item2: (
            <p className={styles.supplierTable__text__opacity__underline}>
              {supplier.taxId} <HiOutlineDocumentDuplicate />
            </p>
          ),
        },
        {
          item1: (
            <p
              key={`bank-${supplier.id}`}
              className={
                styles.supplierTable__text__opacity__underline__leftSide
              }
            >
              Nombre del banco
            </p>
          ),
          item2: (
            <p className={styles.supplierTable__text__opacity}>
              {supplier.bankName}
            </p>
          ),
        },
        {
          item1: (
            <p
              key={`account-${supplier.id}`}
              className={
                styles.supplierTable__text__opacity__underline__leftSide
              }
            >
              Nro. de cuenta
            </p>
          ),
          item2: (
            <p className={styles.supplierTable__text__opacity__underline}>
              {supplier.bankAccount} <HiOutlineDocumentDuplicate />
            </p>
          ),
        },
        {
          item1: (
            <p
              key={`interbank-${supplier.id}`}
              className={
                styles.supplierTable__text__opacity__underline__leftSide
              }
            >
              Nro. de interbanking
            </p>
          ),
          item2: (
            <p className={styles.supplierTable__text__opacity__underline}>
              {supplier.interbankAccount} <HiOutlineDocumentDuplicate />
            </p>
          ),
        },
        {
          item1: (
            <p
              key={`credit-${supplier.id}`}
              className={
                styles.supplierTable__text__opacity__underline__leftSide
              }
            >
              Crédito
            </p>
          ),
          item2: (
            <p className={styles.supplierTable__text__opacity}>
              {supplier.creditDays}
            </p>
          ),
        },
      ],
    }));
  }, [suppliers]);

  return { suppliersDataTableProps };
};

export default useDataTable;
