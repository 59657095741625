import { Dispatch, useEffect, useState } from "react";
import { SUPPLIER_ROUTES } from "@Models/supplier.routes";
import { IHeaderNavigationProps } from "@Models/interfaces/Resources.interfaces";
import {
  HeaderNavigationBillDetail,
  HeaderNavigationSupplierCreation,
  HeaderNavigationSupplierEdition,
  HeaderNavigationSupplierList,
} from "../HeaderNavigation.const";
import { useLocation } from "react-router-dom";
import useModalStore from "@/store/Modal.store";

interface IHeaderNavigationConfigList {
  render_path: string | RegExp;
  header_config: IHeaderNavigationProps;
}

interface IHeaderProp {
  headerProp: IHeaderNavigationProps | undefined;
  setHeaderProp: Dispatch<IHeaderNavigationProps>;
}

// Custom hook for header navigation
export const useHeaderNavigation = (): IHeaderProp => {
  // State to manage header properties
  const [headerProp, setHeaderProp] = useState<
    IHeaderNavigationProps | undefined
  >(undefined);
  const location = useLocation();
  const { toggleCreationSupplierModal } = useModalStore();

  // Configuration list for header navigation
  const HEADER_NAVIGATION_CONFIGS_LIST: IHeaderNavigationConfigList[] = [
    {
      render_path: SUPPLIER_ROUTES.SHOW_LIST,
      header_config: HeaderNavigationSupplierList(() =>
        toggleCreationSupplierModal()
      ),
    },
    {
      render_path: SUPPLIER_ROUTES.CREATE_SUPPLIER,
      header_config: HeaderNavigationSupplierCreation,
    },
    {
      // Edit supplier route pattern using RegExp to match /edit/:id
      render_path: new RegExp(`${SUPPLIER_ROUTES.EDIT_SUPPLIER}/\\d+`),
      header_config: HeaderNavigationSupplierEdition,
    },
  ];

  useEffect(() => {
    const updateHeaderConfig = () => {
      const CURRENT_PATH = location.pathname;

      // Find the appropriate header configuration for the current path
      const HEADER_CONFIG = HEADER_NAVIGATION_CONFIGS_LIST.find((config) =>
        typeof config.render_path === "string"
          ? config.render_path === CURRENT_PATH
          : config.render_path.test(CURRENT_PATH)
      );

      if (HEADER_CONFIG) {
        // Set the header properties if a matching configuration is found
        setHeaderProp(HEADER_CONFIG.header_config);
      } else {
        setHeaderProp(null);
      }
    };

    // Update header config on initial load and whenever location changes
    updateHeaderConfig();
  }, [location]);

  return {
    headerProp,
    setHeaderProp,
  };
};
