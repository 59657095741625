import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";
import getSuppliersByNameAPI from "@Services/get_services/getSuppliersByName.services";

const searchSupplierByNameAdapter = async (value: string) => {
  try {
    // Fetch raw supplier data from the API
    //@ts-ignore
    const response = await getSuppliersByNameAPI(value);

    return {
      isSuccess: true,
      data: response.data,
      userMessage: "",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      userMessage: GENERIC_ERROR_MSG,
    };
  }
};

export default searchSupplierByNameAdapter;
