import { create } from "zustand";
import { IBankStore } from "./types/bankType";
import {
  getBanksListController,
  loadBanksIfEmptyController,
} from "./controller/banks.controller";

// Crea la tienda usando Zustand
const useBankStore = create<IBankStore>((set, get) => ({
  banks: [],

  // Función para obtener la lista de bancos y actualizar el estado
  getBanksListsStore: async () => {
    return await getBanksListController();
  },

  // Función para cargar los bancos si no están ya en el estado
  loadBanksIfEmpty: async () => {
    return await loadBanksIfEmptyController();
  },
}));

export default useBankStore;
