export const GENERIC_ERROR_MSG = "¡Ups! Ha ocurrido un error inesperado.";
export const GENERIC_SUCCESS_MSG = "La operación se ha realizado con éxito";

export const GENERIC_SUCCESS_TOASTER_MSG = {
  message: GENERIC_SUCCESS_MSG,
  isError: false,
};

export const GENERIC_FAIL_TOASTER_MSG = {
  message: GENERIC_ERROR_MSG,
  isError: true,
};

export const REQUIERE_MSG = "Este campo es obligatorio.";
