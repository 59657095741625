import { SupplierBackendRequest } from "@Models/interfaces/SupplierCreation.interface";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { CREATE_SUPPLIER, PARTIAL_URL } from "@Models/api/api.routes";
import { handleApiError, handleApiResponse } from "../api.services";
import { ISupplierCreationResponse } from "@Models/interfaces/services.response";

/**
 * Simulates an API call to create a new supplier.
 * This function mimics an asynchronous API request and returns a simulated response.
 * @param supplierData - The supplier data to be sent to the backend.
 * @returns {Promise<IApiResponse<ISupplierCreationResponse>>} - A promise that resolves to the simulated API response.
 */
const createPartialPaymentAPI = async (supplierData: {
  billId: number;
  amount: number;
}): Promise<IApiResponse<any>> => {
  try {
    const response = await axios.post(PARTIAL_URL, null, {
      params: {
        ...supplierData,
      },
    });

    // Return a successful response
    return handleApiResponse(response, response.data);
  } catch (error) {
    // Return an error response with an appropriate message
    return handleApiError(error);
  }
};

export default createPartialPaymentAPI;
