import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import { FormProvider, UseFormReturn, useWatch } from "react-hook-form";
import style from "./SupplierInformation.module.css";
import useGetSupplierInformation from "./hook/useGetSupplierInformation";
import { useHeaderNavigationContext } from "@Contexts/HeaderNavigation/HeaderNavigation.context";
import useHeaderNavigationProps from "../../hooks/useHeaderNavigationProps";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";
import { useParams } from "react-router-dom";
import EmptyState from "@Components/ui/EmptyState/EmptyState";
import { SupplierCreationForm } from "@Pages/SupplierCreation";
import useGetBankList from "@Pages/SupplierCreation/components/SupplierForm/hooks/useGetBanks";
import { useEffect, useState } from "react";
import useSupplierBillStore from "@/store/SupplierBills";

const REQUIERE_MSG = "Este campo es obligatorio.";

const SupplierInformationView = ({
  SupplierForm,
}: {
  SupplierForm: UseFormReturn<ISupplierForm, any, undefined>;
}) => {
  const headerContext = useHeaderNavigationContext();
  const { supplierValues, hasErrorFetchingData } = useGetSupplierInformation();
  const supplierParams = useParams();
  const watch = useWatch(SupplierForm);
  const [bankSelected, setBankSelected] = useState(-1);

  const { isEditingMode } = useHeaderNavigationProps(
    headerContext,
    SupplierForm,
    supplierValues,
    supplierParams.idSupplier,
    watch
  );

  const { bankOptions } = useGetBankList();

  useEffect(() => {
    if (!supplierValues || !bankOptions) return;
    console.log(
      bankOptions.find((b) => b.name === supplierValues.bankName)?.value
    );
    setBankSelected(
      bankOptions.find((b) => b.name === supplierValues.bankName)?.value
    );
  }, [bankOptions]);

  if (hasErrorFetchingData)
    return (
      <EmptyState
        title="Error al cargar la información"
        message="No se pudo cargar la información del proveedor. Por favor intente mas tarde"
        isError={true}
        icon="ERROR"
      />
    );
  if (supplierValues === null) return <SkeletonText />;
  if (bankSelected === -1) return <SkeletonText />;
  return (
    <section className={style.SupplierInformation__section}>
      <FormProvider {...SupplierForm}>
        <SupplierCreationForm
          SupplierForm={SupplierForm}
          isDisabled={!isEditingMode}
          defaultValues={{
            ...supplierValues,
            bankId: bankSelected,
          }}
          fullWidth={true}
        />
        {/* <FormInput
          label="Razón Social"
          nameControl="businessName"
          defaultValue={supplierValues.businessName}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
          }}
        />
        <FormInput
          label="Nro. Ruc, Rut ó Vat Number"
          nameControl="taxIdVat"
          defaultValue={supplierValues.taxIdVat}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
          }}
        />
        <FormInput
          label="País"
          nameControl="country"
          type="select"
          options={[{ value: 1, name: "Perú" }]}
          defaultValue={1}
          rules={{
            required: REQUIERE_MSG,
            disabled: true,
          }}
        />
        <FormInput
          label="Dirección"
          nameControl="address"
          defaultValue={supplierValues.address}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
            pattern: {
              value: ADDRESS_PATTERN,
              message: "Ingresa una dirección válida",
            },
          }}
        />
        <FormInput
          label="Nro. de cuenta"
          nameControl="accountNumber"
          defaultValue={supplierValues.accountNumber}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
          }}
        />
        <FormInput
          label="Nro. de Cuenta Interbancario"
          nameControl="interbankAccountNumber"
          defaultValue={supplierValues.interbankAccountNumber}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
          }}
        />
        <FormInput
          label="Crédito"
          nameControl="credit"
          type="select"
          options={TYPE_CREDIT}
          defaultValue={Number(supplierValues.creditTerms)}
          rules={{
            required: REQUIERE_MSG,
            disabled: !isEditingMode,
          }}
        /> */}
      </FormProvider>
    </section>
  );
};

export default SupplierInformationView;
