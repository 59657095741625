import { ISupplierBillsById } from "@Models/interfaces/Adapters.interfaces";
import { SupplierInvoiceBackend } from "@Models/interfaces/services.response";
import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";
import getBillsByProviderById from "@Services/get_services/getSupplierById.services";

export interface SupplierInvoice {
  invoiceCode: string; // Código de la factura
  registeredBy: string | null; // Usuario que registró la factura, puede ser null
  registrationDate: string; // Fecha de registro de la factura
  totalAmount: number; // Monto total de la factura
  productQuantity: number; // Cantidad de productos en la factura
  paymentStatus: string; // Estado del pago de la factura
  dueDate: string; // Fecha de vencimiento de la factura
  issueDate: string; // Fecha de emisión de la factura
  isPaid: boolean;
  billId: number;
}

export const mapInvoiceData = (
  data: SupplierInvoiceBackend[]
): SupplierInvoice[] => {
  return data.map((invoice) => ({
    invoiceCode: invoice.codigoFactura.toString(),
    registeredBy: invoice.registradoPor || "Sin especificar",
    registrationDate: invoice.fechaRegistro || "Sin especificar",
    totalAmount: invoice.total || 0,
    productQuantity: invoice.cantidadProductos || 0,
    paymentStatus: invoice.estadoPago || "Sin especificar",
    dueDate: invoice.fechaVencimiento || "Sin especificar",
    issueDate: invoice.fechaEmision || "Sin especificar",
    isPaid: invoice.estadoPago === "1",
    billId: invoice.facturaId,
  }));
};

/**
 * Fetches and adapts the invoices for a given supplier by ID.
 * @param supplierId - The ID of the supplier to fetch invoices for.
 * @returns A promise that resolves to the adapted supplier invoice response.
 */
export const getSupplierInvoicesAdapter = async (
  supplierId: number
): Promise<ISupplierBillsById> => {
  try {
    // Fetch raw invoice data from the API
    const response = await getBillsByProviderById(supplierId);

    const { data } = response;

    // Map the raw data to the SupplierInvoice format
    const mappedData = mapInvoiceData(data);

    return {
      isSuccess: response.isSuccess,
      data: mappedData.reverse(),
      userMessage: response.isSuccess ? "" : GENERIC_ERROR_MSG,
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      userMessage: GENERIC_ERROR_MSG,
    };
  }
};
