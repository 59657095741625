import {
  IUpdateSupplierRequest,
  IUpdateSupplierResponse,
  Supplier,
} from "@Models/interfaces/Adapters.interfaces";

import {
  GENERIC_ERROR_MSG,
  GENERIC_SUCCESS_MSG,
} from "@Models/messages/generalMessages.messages";
import toggleBillSupplierAPI from "@Services/put_services/toggleBillStatus.services copy";

interface IAdapterResponse {
  userMessage: string;
  isSuccess: boolean;
  data: IUpdateSupplierResponse;
}

export const togglePaymentStatusAdapter = async (
  billId: number,
  isPaid: boolean
): Promise<IAdapterResponse> => {
  const response = await toggleBillSupplierAPI({
    billId: billId,
    status: isPaid ? 1 : 0,
  });

  if (response.isSuccess) {
    return {
      userMessage: GENERIC_SUCCESS_MSG,
      isSuccess: true,
      data: null,
    };
  }

  return {
    userMessage: response.userMessage || GENERIC_ERROR_MSG,
    isSuccess: false,
    data: null,
  };
};
