import React from "react";
import { TabNavigation } from "@viuti/recursos";
import SupplierListView from "./views/SupplierListView";
import BillsToPayView from "./views/BillsToPayListView";
import SupplierCreationPage from "@Pages/SupplierCreation/SupplierCreationView";
import useModalStore from "@/store/Modal.store";
import styles from "./styles/SupplierMainView.module.css";
import useSupplierBillStore from "@/store/SupplierBills";

const NAVIGATION_VIEWS = [
  {
    name: "Listado de proveedores",
    component: <SupplierListView />,
  },
  {
    name: "Cuentas por pagar",
    component: <BillsToPayView />,
  },
];

const SupplierMainView: React.FC = () => {
  const { creationSupplierModal } = useModalStore();
  const billsStore = useSupplierBillStore();

  return (
    <section className={styles.mainView__grid}>
      <TabNavigation views={NAVIGATION_VIEWS} initialView={0} />

      {/* Conditionally render the supplier creation modal if it is visible */}
      {creationSupplierModal && <SupplierCreationPage />}
    </section>
  );
};

export default SupplierMainView;
