import { createContext, ReactNode, useContext, useEffect } from "react";
import { useHeaderNavigation } from "./hook/useHeaderNavigation.hook";

// 🌟 Context initial interface and values
interface IHeaderNavigationContextValue {
  headerProp: any;
  setHeaderProp: React.Dispatch<React.SetStateAction<any>>;
}

const HeaderNavigationContextInitialValue: IHeaderNavigationContextValue = {
  headerProp: null,
  setHeaderProp: () => {},
};

// 🌟 Create the Header Navigation Context
export const HeaderNavigationContext = createContext(
  HeaderNavigationContextInitialValue
);

// 🌟 Context provider to wrap around components needing the header navigation
export const HeaderNavigationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // 🎨 State to manage header properties
  const { headerProp, setHeaderProp } = useHeaderNavigation();

  if (!headerProp) return;
  return (
    // 🛠️ Provide the context to the children
    <HeaderNavigationContext.Provider value={{ headerProp, setHeaderProp }}>
      {children}
    </HeaderNavigationContext.Provider>
  );
};

// ⚠️ Hook to use the header navigation context with error handling
export const useHeaderNavigationContext = () => {
  const context = useContext(HeaderNavigationContext);
  if (context === undefined)
    throw new Error(
      "HeaderNavigationContext must be used within a HeaderNavigationProvider"
    );

  return context;
};
