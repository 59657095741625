// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z83_2i6XJKO3FfyJOypo {
  width: 100%;
  max-width: 25rem;
  padding: 2rem 1rem;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/EmptyState/EmptyState.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".emptyState__container {\n  width: 100%;\n  max-width: 25rem;\n  padding: 2rem 1rem;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyState__container": `z83_2i6XJKO3FfyJOypo`
};
export default ___CSS_LOADER_EXPORT___;
