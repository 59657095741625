import { ISupplierForm } from "@Models/interfaces/SupplierCreation.interface";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const useValidateSupplierForm = () => {
  const [canCreateEditSupplier, setCanCreateEditSupplier] = useState(false);

  // Create the form with react-hook-form
  const supplierForm = useForm<ISupplierForm>();

  const { watch, formState } = supplierForm;

  const formDataWatch = watch();

  useEffect(() => {
    if (formState.isValid) {
      const values = supplierForm.getValues();
      const valuesArray = Object.values(values);
      const hasEmptyString = valuesArray.some((value) => value === "");
      if (!hasEmptyString) setCanCreateEditSupplier(true);
      else setCanCreateEditSupplier(false);
    }
  }, [formDataWatch]);

  return {
    canCreateEditSupplier,
    supplierForm,
  };
};

export default useValidateSupplierForm;
