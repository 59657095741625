import { IAdapterCreateResponse } from "@Adapters/createSupplier.adapter";

const adaptTheSupplierStoreResponse = (
  response: IAdapterCreateResponse,
  values
) => {
  console.log(response);
  return {
    id: response.data.supplierData.id.toString(),
    name: response.data.supplierData.fullName,
    taxId: response.data.supplierData.taxType,
    taxNumber: response.data.supplierData.taxNumber,
    bankName: response.data.supplierData.bankName,
    bankAccount: response.data.supplierData.accountNumber,
    interbankAccount: response.data.supplierData.interbankingNumber,
    creditDays: response.data.supplierData.creditLimit.toString(),
    address: response.data.supplierData.address,
    country: response.data.supplierData.countryName,
  };
};

export default adaptTheSupplierStoreResponse;
