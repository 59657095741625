import { BankAdapterDataList } from "@Models/interfaces/Adapters.interfaces";
import { GENERIC_ERROR_MSG } from "@Models/messages/generalMessages.messages";

export const getBanksListAdapter = async (): Promise<{
  isSuccess: boolean;
  userMessage: string;
  data: BankAdapterDataList[];
}> => {
  //try {
  // Fetch raw supplier data from the API
  //const response = await getBanksAPI(); //TODO: Create the bank route

  // const { data } = response;

  // // Map the raw data to the Supplier format
  // const mappedData = mapSupplierData(data.suppliers);

  return {
    isSuccess: true,
    data: [
      {
        value: 1,
        name: "Banco de Comercio",
      },
      {
        value: 2,
        name: "Banco de Crédito del Perú",
      },
      {
        value: 3,
        name: "Banco Interamericano de Finanzas",
      },
      {
        value: 4,
        name: "BBVA",
      },
      {
        value: 5,
        name: "Interbank",
      },
      {
        value: 6,
        name: "Scotiabank",
      },
    ],
    userMessage: "",
  };
  // } catch (error) {
  //   return {
  //     isSuccess: false,
  //     data: [],
  //     userMessage: GENERIC_ERROR_MSG,
  //   };
  // }
};
