import { DELETE_SUPPLIER } from "@Models/api/api.routes";
import { IApiResponse } from "@Models/api/apiResponses";
import axios from "axios";
import { handleApiResponse, handleApiError } from "../api.services";

/**
 * Simulates an API call to delete suppliers.
 * This function mimics an asynchronous API request and returns a simulated response.
 * @param id - The ID of the supplier to delete.
 * @returns {Promise<IApiResponse>} - A promise that resolves to the API response.
 */
const deleteSuppliersAPI = async (id: number): Promise<IApiResponse> => {
  try {
    const response = await axios.delete(DELETE_SUPPLIER, {
      params: { supplierId: id },
    });

    // Use handleApiResponse to process the response
    return handleApiResponse(response);
  } catch (error) {
    // Use handleApiError to handle any errors
    return handleApiError(error);
  }
};

export default deleteSuppliersAPI;
