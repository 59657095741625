export const TYPE_IDENTIFICATION = [
  { value: 1, name: "Nro. RUC" },
  { value: 2, name: "Nro. RUT" },
  { value: 3, name: "Nro. VAT" },
];

export const TYPE_CREDIT = [
  { value: 1, name: "Al contado" },
  { value: 7, name: "7 Días" },
  { value: 15, name: "15 Días" },
  { value: 30, name: "30 Días" },
  { value: 45, name: "45 Días" },
  { value: 60, name: "60 Días" },
  { value: 90, name: "90 Días" },
];
