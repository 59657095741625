import App from "@Pages/App";
import { CriticalErrorPage } from "@viuti/recursos";
import "./interceptors/axios.interceptor";

/**
 * Application root component, rendering the App component and handling critical errors.
 */
export default function ApplicationRoot(props) {
  return (
    // <ErrorBoundary
    //   // Renders the critical error page if an error occurs
    //   fallback={<CriticalErrorPage />}
    // >
    <App />
    // </ErrorBoundary>
  );
}
