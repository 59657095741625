import React from "react";
import styles from "./SupplierCreationForm.module.css";
import FormInput from "@Components/ui/FormInput/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { ADDRESS_PATTERN } from "@Models/patter_values/formReguex";
import {
  TYPE_CREDIT,
  TYPE_IDENTIFICATION,
} from "@Models/form_validators/CreationForm.validators";
import useGetBankList from "./hooks/useGetBanks";
import { REQUIERE_MSG } from "@Models/messages/generalMessages.messages";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";

const SupplierCreationForm: React.FC<any> = ({
  SupplierForm,
  defaultValues,
  isDisabled = false,
  fullWidth = false,
}) => {
  const { bankOptions } = useGetBankList();
  return (
    <div className={styles.supplierCreationForm}>
      <FormProvider {...SupplierForm}>
        <section
          className={
            styles.flex__gap + " " + fullWidth &&
            styles.supplierCreationForm__fullWidth
          }
        >
          <FormInput
            label="Razón Social"
            defaultValue={defaultValues && defaultValues.businessName}
            nameControl={"businessName"}
            rules={{
              required: REQUIERE_MSG,
              disabled: isDisabled,
            }}
          />
          {/* <section className={fullWidth ? styles.supplierCreationForm__grid__column2 : styles.supplierCreationForm__grid__column2__maxWidth}> */}
          <FormInput
            label="País"
            nameControl={"country"}
            type="select"
            options={[{ value: 1, name: "Perú" }]}
            defaultValue={1}
            rules={{
              disabled: true,
            }}
          />
          {/* </section> */}
        </section>
        <section
          className={fullWidth && styles.supplierCreationForm__fullWidth}
        >
          <section className={styles.supplierCreationForm__grid__column2}>
            <FormInput
              label="Tipo de identificación"
              nameControl={"typeIdVat"}
              defaultValue={defaultValues ? defaultValues.typeIdVat : 1}
              type="select"
              options={TYPE_IDENTIFICATION}
              rules={{
                required: REQUIERE_MSG,
                disabled: isDisabled,
              }}
            />
            <FormInput
              label="Nro. de identificación"
              nameControl={"taxIdVat"}
              defaultValue={defaultValues && defaultValues.taxIdVat}
              rules={{
                required: REQUIERE_MSG,
                disabled: isDisabled,
              }}
            />
          </section>
          <FormInput
            label="Nombre del banco"
            nameControl={"bankName"}
            type="select"
            options={bankOptions}
            defaultValue={defaultValues ? defaultValues.bankId : 1}
            rules={{
              required: REQUIERE_MSG,
              disabled: isDisabled,
            }}
          />
        </section>
        <section
          className={fullWidth && styles.supplierCreationForm__fullWidth}
        >
          <section className={styles.supplierCreationForm__grid__column2}>
            <FormInput
              label="Nro. de cuenta"
              nameControl={"accountNumber"}
              defaultValue={defaultValues && defaultValues.accountNumber}
              rules={{
                required: REQUIERE_MSG,
                disabled: isDisabled,
              }}
            />
            <FormInput
              label="Nro. de Cuenta Interbancario"
              nameControl={"interbankAccountNumber"}
              defaultValue={
                defaultValues && defaultValues.interbankAccountNumber
              }
              rules={{
                required: REQUIERE_MSG,
                disabled: isDisabled,
              }}
            />
          </section>
          <FormInput
            label="Crédito"
            nameControl={"creditTerms"}
            type="select"
            options={TYPE_CREDIT}
            defaultValue={
              defaultValues
                ? TYPE_CREDIT.find((c) => c.value === defaultValues.creditTerms)
                    ?.value
                : 1
            }
            rules={{
              required: REQUIERE_MSG,
              disabled: isDisabled,
            }}
          />
          {!defaultValues && <br />}
          <FormInput
            label="Dirección"
            nameControl={"address"}
            defaultValue={defaultValues && defaultValues.address}
            rules={{
              required: REQUIERE_MSG,
              pattern: {
                value: ADDRESS_PATTERN,
                message: "Ingresa una dirección válida",
              },
              disabled: isDisabled,
            }}
          />
        </section>
      </FormProvider>
    </div>
  );
};

export default SupplierCreationForm;
