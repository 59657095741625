import { CREATE_SUPPLIER_UUID } from "@Models/contracts/securityAccess.contracts";
import { IHeaderNavigationProps } from "@Models/interfaces/Resources.interfaces";
import { validateModuleAccess } from "@viuti/recursos";
import { navigateToUrl } from "single-spa";

export const HeaderNavigationSupplierList = (
  navigateToCreateSupplier: () => void
): IHeaderNavigationProps => ({
  title: "Gestión de proveedores",
  previousAction: false,
  buttonProps: {
    textBttn: "Nuevo proveedor",
    handleClick: () =>
      validateModuleAccess(CREATE_SUPPLIER_UUID) && navigateToCreateSupplier(),
    isDisabled: false,
    isHidden: false,
    isPrimary: true,
    isLoading: false,
  },
});

export const HeaderNavigationSupplierCreation: IHeaderNavigationProps = {
  title: "Crear un nuevo proveedores",
  previousAction: false,
  buttonProps: {
    textBttn: "Crear proveedor",
    handleClick: () => {},
    isDisabled: false,
    isHidden: false,
    isPrimary: true,
    isLoading: false,
  },
};

export const HeaderNavigationSupplierEdition: IHeaderNavigationProps = {
  title: "Detalle del proveedor",
  previousAction: () => navigateToUrl("../"),
  buttonProps: {
    textBttn: "Editar",
    handleClick: () => {},
    isDisabled: false,
    isHidden: false,
    isPrimary: true,
    isLoading: false,
  },
};

export const HeaderNavigationBillDetail: IHeaderNavigationProps = {
  title: "Detalle de la factura",
  previousAction: () => navigateToUrl("../"),
  buttonProps: {
    textBttn: "Editar",
    handleClick: () => {},
    isDisabled: false,
    isHidden: true,
    isPrimary: true,
    isLoading: false,
  },
};
