// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wYK5y4rhHY_CqKfHFfjQ {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
  height: fit-content;
  overflow: hidden;
}

.jw9LrXRwy9izAMISzWIA {
  margin-top: -0.5rem;
  margin-bottom: -2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SupplierList/styles/SupplierMainView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".mainView__grid {\n  display: grid;\n  gap: 2rem;\n  margin-top: 2rem;\n  height: fit-content;\n  overflow: hidden;\n}\n\n.__header {\n  margin-top: -0.5rem;\n  margin-bottom: -2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainView__grid": `wYK5y4rhHY_CqKfHFfjQ`,
	"__header": `jw9LrXRwy9izAMISzWIA`
};
export default ___CSS_LOADER_EXPORT___;
