//@ts-ignore
import { ModalAcceptCancel, NoInfoToShow, TableWrapper } from "@viuti/recursos";
import { useState, Fragment, useEffect } from "react";
import styles from "./PaymentHistory.module.css";
import { PartialPayment } from "./PartialPayment";
import useSupplierBillStore from "@/store/SupplierBills";
import { useToasterContext } from "@Contexts/Toaster/Toaster.context";
import {
  GENERIC_FAIL_TOASTER_MSG,
  GENERIC_SUCCESS_TOASTER_MSG,
} from "@Models/messages/generalMessages.messages";
import getPaymentHistoryAPI from "@Services/get_services/getPaymentHistou";
import EmptyState from "../EmptyState/EmptyState";
import { formatToSoles } from "@/utils/formatToSoles.util";
import { convertIsoDateToFormatted } from "@/utils/dateFormat.util";
import { HiInformationCircle } from "react-icons/hi2";
import createPartialPaymentAPI from "@Services/post_services/createPartialPayment.service";

export const PaymentHistory = ({
  visible,
  setVisible,
  setVisiblePartial,
  visiblePartial,
  totalFactura,
  toggleSupplierBillPaidStatus,
  idFactura,
}) => {
  const supplierBill = useSupplierBillStore();
  const [h, setH] = useState([]);
  const [error, setError] = useState(false);
  const toaster = useToasterContext();
  const [total, setTotal] = useState(null);
  // Handler para cerrar el modal
  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    (async () => {
      const res = await getPaymentHistoryAPI(idFactura);
      if (res.isSuccess) {
        setH(res.data);
        const totalAmount = res.data.reduce(
          (acc, item) => acc + item.amount,
          0
        );
        setTotal(totalAmount);
      } else setError(true);
    })();
  }, []);

  // Handler para confirmar el pago completo
  const handleConfirmFullPayment = async () => {
    await toggleSupplierBillPaidStatus();
    await createPartialPaymentAPI({
      billId: idFactura,
      amount: totalFactura - total,
    });
    setVisible(false);
  };

  // Handler para mostrar el modal de pago parcial
  const handleShowPartialPayment = () => {
    setVisiblePartial(true);
  };

  useEffect(() => {
    if (total === null) return;
    if (formatToSoles(totalFactura - total) === "S/ 0.00") {
      toggleSupplierBillPaidStatus();
    }
  }, [total]);

  return (
    <Fragment>
      <ModalAcceptCancel
        title="Historial de pago"
        visibility={{
          isVisible: visible,
          setIsVisible: setVisible,
        }}
        showButtons={{
          showButtonOne: true,
          showButtonTwo: true,
          showButtonClose: true,
        }}
        buttonTwo={{
          textButtonTwo: "Confirmar el pago completo",
          actionButtonTwo: handleConfirmFullPayment,
        }}
        buttonOne={{
          textButtonOne: "Realizar pago parcial",
          actionButtonOne: handleShowPartialPayment,
        }}
        actionButtonClose={handleClose}
      >
        <div className={styles.modal}>
          {error && (
            <EmptyState
              isError={true}
              title="Error al cargar el historial"
              message="No se pudo cargar el historial, por favor intente mas tarde."
              icon="BILL"
            />
          )}
          {!error && (
            <TableWrapper
              titles={["Fecha de pago", "Registrado por", "Monto"]}
              data={h.map((e) => ({
                date: convertIsoDateToFormatted(e.date).longDate,
                registerBy: e.registeredBy,
                amount:
                  e.amount < 0 ? (
                    <p className={styles.negative}>{formatToSoles(e.amount)}</p>
                  ) : (
                    <p>{formatToSoles(e.amount)}</p>
                  ),
              }))}
            />
          )}

          <section className={styles.info}>
            <HiInformationCircle color="#45348e" size={14} />
            <p>
              El monto total en efectivo pagado hasta el día de la fecha es de{" "}
              <b>{formatToSoles(total)}</b>. Se registra una diferencia de{" "}
              <b>{formatToSoles(totalFactura - total)}</b> para finaliza el pago
              de la misma
            </p>
          </section>
        </div>
      </ModalAcceptCancel>
    </Fragment>
  );
};
