import { useEffect, useState } from "react";
import styles from "./SupplierBillsPreview.module.css";
import { ModalAcceptCancel } from "@viuti/recursos";
import {
  getSupplierDetailInvoiceAdapter,
  SupplierInvoiceDetail,
} from "@Adapters/getBillDetail.adapter";
import SkeletonText from "@Components/ui/SkeletonsView/SkeletonTextsView/SkeletonText.view";
import { ISupplierBillDetailById } from "@Models/interfaces/Adapters.interfaces";
import { SupplierInvoice } from "@Adapters/getBillsBySupplier.adapter";
import { convertIsoDateToFormatted } from "@/utils/dateFormat.util";
import { formatToSoles } from "@/utils/formatToSoles.util";
import SkeletonModal from "@Components/ui/SkeletonsView/SkeletonModalView/SkeletonModal.view";

const SupplierBillsPreview = ({
  id,
  setShowVisible,
  detail,
}: {
  id: number;
  setShowVisible: any;
  detail: SupplierInvoice;
}) => {
  const [detailBill, setDetailBill] = useState<ISupplierBillDetailById>(null);
  useEffect(() => {
    (async () => {
      const billDetail = await getSupplierDetailInvoiceAdapter(id);
      setDetailBill(billDetail);
    })();
  }, []);

  return (
    <ModalAcceptCancel
      visibility={{
        isVisible: true,
        setIsVisible: () => setShowVisible({ show: false, id: null }),
      }}
      title="Detalle #00010100010"
      showButtons={{
        showButtonClose: true,
      }}
      actionButtonClose={() => setShowVisible({ show: false, id: null })}
    >
      {detailBill === null ? (
        <SkeletonModal />
      ) : (
        <section className={styles.ticketContainer}>
          <header className={styles.header}>
            <div>
              <p>Fecha de emisión</p>
              <h4>{convertIsoDateToFormatted(detail.issueDate).shortDate}</h4>
            </div>
            <div>
              <p>Fecha de registro</p>
              <h4>
                {convertIsoDateToFormatted(detail.registrationDate).shortDate}
              </h4>
            </div>
            <div>
              <p>Registrado por</p>
              <h4>{detail.registeredBy}</h4>
            </div>
          </header>
          <br />
          <section className={styles.product__list_section}>
            {detailBill.data.length === 0 && (
              <article className={styles.producto__item}>
                <h4>No hay productos registrados en esta factura</h4>
              </article>
            )}
            {detailBill.data.map((b, i) => {
              return (
                <article className={styles.producto__item} key={i}>
                  <h4>{b.name}</h4>
                  <p></p>
                  <p>Cantidad</p>
                  <p className={styles.producto__item_textRight}>
                    x{b.quantity}
                  </p>
                  <p>Precio unitario</p>
                  <p className={styles.producto__item_textRight}>
                    {formatToSoles(b.price)}
                  </p>
                  <p>Precio total</p>
                  <p className={styles.producto__item_textRight__bold}>
                    {formatToSoles(b.price * b.quantity)}
                  </p>
                </article>
              );
            })}
          </section>

          <section className={styles.totales}>
            <p>Subtotal</p>
            <p className={styles.producto__item_textRight}>
              {formatToSoles(detail.totalAmount - detail.totalAmount * 0.18)}
            </p>
            <p>IGV (18%)</p>
            <p className={styles.producto__item_textRight}>
              {formatToSoles(detail.totalAmount * 0.18)}
            </p>
            <strong>Total</strong>
            <strong className={styles.producto__item_textRight__bold}>
              {formatToSoles(detail.totalAmount)}
            </strong>
          </section>
        </section>
      )}
    </ModalAcceptCancel>
  );
};

export default SupplierBillsPreview;
