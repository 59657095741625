import { DeleteModalProvider } from "@Contexts/DeleteModal/DeleteModal.context";
import { FilterProvider } from "@Contexts/Filter/Filter.context";
import { HeaderNavigationProvider } from "@Contexts/HeaderNavigation/HeaderNavigation.context";
import { ToasterProvider } from "@Contexts/Toaster/Toaster.context";
import React, { ReactNode } from "react";

/**
 * Component that wraps its children with the HeaderNavigationProvider.
 * This is used to provide the HeaderNavigation context to all child components.
 *
 * @param {ReactNode} children - The components to be wrapped by the provider.
 * @returns {JSX.Element} The wrapped component.
 */
const RenderProviderTemplate: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <ToasterProvider>
      <FilterProvider>
        <DeleteModalProvider>
          <HeaderNavigationProvider>{children}</HeaderNavigationProvider>
        </DeleteModalProvider>
      </FilterProvider>
    </ToasterProvider>
  );
};

export default RenderProviderTemplate;
