import { useState, useEffect } from "react";

/**
 * Custom hook to get the current width of the window.
 * @returns {number} - The current width of the window.
 */
const useWindowWidth = () => {
  // State to store the current width of the window.
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    /**
     * Updates the width state with the current window width.
     * This function is called on window resize events.
     */
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add an event listener to update width on window resize.
    window.addEventListener("resize", handleResize);

    // Initial call to set the width when the component mounts.
    handleResize();

    /**
     * Cleanup function to remove the event listener when the component unmounts.
     * This prevents memory leaks and ensures the event listener is removed properly.
     */
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount.

  return width;
};

export default useWindowWidth;
